import {SAVE_CASH_REQUEST_DTO} from '../constant';

export const cashReqReducer = (state = null, action) => {
    switch (action.type) {
        case SAVE_CASH_REQUEST_DTO:
            return action.payload;
        default:
            return state;
    }
};
