import React from "react";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import api from "../api/api/index";
import { Modal } from "antd";
import { accessTokenState, nameState, phoneState, emailState } from '../atoms';
import withRecoil from '../withRecoil';
import _ from 'lodash';
import queryString from "query-string";
import Header from "../components/Header";


function error(title, content) {
  Modal.error({
    title: title,
    content: content,
  });
}

class DashboardMain extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      escrow_amount: null,
      showBanner: false,
      showPayment: false,
    };
  }


  componentDidMount() {
    this.updateEscrow();
    this.checkEmailVerification();
    this.getAccountInfo();
  }

  checkEmailVerification = () => {
    let body = {
      access_token: this.props.recoilStates.accessTokenState.state,
      device_type: 5,
      device_token: 1,
      app_version: 1,
    };
    fetch(`${api.main}/access_token_login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: queryString.stringify(body),
    })
    .then(response => {
      if (!response.ok) {
          throw new Error(`Status: ${response.status}`);
      }
      return response.json();
    })
    .then(res => {
      if(res.status === 105 && res.data.email_verified === 0){
        this.setState({showBanner: true})
      }
    })
    .catch(error => {
      console.error('Error verifying access:', error);
    });
  }

  getAccountInfo = () => {
    fetch(`${api.main}/get_user_profile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        access_token: this.props.token,
      }),
    })
      .then(res => {
        if (!res.ok) {
          return res.json().then(errorData => {
            throw new Error(`Error: ${errorData.message}`);
          });
        }
        return res.json();
      })
      .then(data => {
        if (data.status !== 105) {
          throw new Error(`Error: ${data.message}`);
        } else {
          if (typeof data.data.name !== 'undefined' && typeof data.data.phone !== 'undefined') {
            this.props.recoilStates[nameState.key].setState(data.data.name);
            this.props.recoilStates[phoneState.key].setState(data.data.phone);
            this.props.recoilStates[accessTokenState.key].setState(this.props.token);
            //console.log('Name:', data.data.name);
            //console.log('Phone:', data.data.phone);
          } else {
            console.error(error);
          }
        }
      })
      .catch(error => {
        console.error('Error getting account info:', error);
      });
  };

  updateEscrow = () => {
    let body = {
      access_token: this.props.token,
      device_type: 5,
      device_token: 1,
      app_version: 1,
    };
    fetch(`${api.main}/access_token_login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: queryString.stringify(body),
    })
    .then(response => {
      if (!response.ok) {
        // Handle HTTP errors
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json(); // Parse the response body to JSON
    })
      .then(res => {
        if (res.status !== 105) {
          error("Error", res.message);
        } else {
          const escrow_amount = _.get(res, 'data.escrow_amount');
          this.setState({ escrow_amount });
          this.props.profile.escrow_amount = res.data.escrow_amount;
        }
      })
      .catch(err => {
        this.props.history.push({ pathname: 'login' });
      });
  };

  togglePayment = () => {
    this.setState(prevState => ({ showPayment: !prevState.showPayment }));
  };


  render() {
    const escrow_amount = _.get(this.state, 'escrow_amount', null);
    const { showBanner, showPayment } = this.state;
    let profile = { escrow_amount: escrow_amount };

    if (!profile) {
      profile = { escrow_amount: -1 };
    }

    let balance = 0;

    if (escrow_amount > 0) {
      let number = escrow_amount.toString();
      balance = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
      <div>
        <Header/>
        {/* Email verification banner */}
        {showBanner && (
          <div className="banner">
            Please verify your email by going to Account Settings → Profile or{" "}
            <span
              onClick={ ()=> this.props.history.push({pathname: "/edit-profile"})}
              className="link"
              role="button"
            >
              click here
            </span>
            .
          </div>
        )}
        <div className="dashboard-container d-flex flex-column justify-content-center align-items-center vh-100">
          <div className="text-center">
            <h2>Your balance</h2>
            <h1 className="display-4">${balance}</h1>
            <div className="buttons-container">
              <Link to={"/dashboard/amount-available"} className="buttons-container btn">
                Pick up cash
              </Link>
              </div>
              {/* <Link to="/dashboard/payment_choice" className="buttons-container btn">
                Top-up balance
              </Link> */}
                    <div>
                    <Link to={"/dashboard/fund-account-paypal"} className="buttons-container btn">
                    Add to Balance
                  </Link>
                  </div>
            </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({}, dispatch);

const mapStateToProps = state => {
  return {
    token: state.token,
    profile: state.profile,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withRecoil(DashboardMain, [accessTokenState, nameState, phoneState, emailState])));
