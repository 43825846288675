import React from "react";
import { Form, Input, Button, Modal } from "antd";
import api from "../api/api/index";
import queryString from "query-string";
function error(title, content) {
  Modal.error({
    title: title,
    content: content,
  });
}
class ForgotPassword extends React.Component {

  handleFormSubmit = (e) => {

    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const email = values.email;
        const finalBody = { email };

        fetch(`${api.main}/forgot_password`, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
            body: queryString.stringify(finalBody),
          })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((res) => {
            //console.log("Server response: ", res);
            
            if (res.status !== 105 && res.status !== 104) {
              Modal.error({
                title: "Error",
                content: res.message || "An unknown error occurred.",
                onOk: () => {
                  this.props.history.push("/forgotpassword");
                },
              });
            } else {
              Modal.success({
                title: "Password Reset",
                content: "Please check your email for a password reset link.",
                onOk: () => {
                  this.props.history.push("/");
                },
              });
            }
          })
          .catch((err) => {
            Modal.error({
              title: "Error",
              content: "Failed to send the password reset request. Please try again later.",
              onOk: () => {
                this.props.history.push("/forgotpassword");
              },
            });
            console.error("Error:", err);
          });
      } else {
        Modal.error({
          title: "Error",
          content: "Please enter a valid email address.",
          onOk: () => {
            this.props.history.push("/forgotpassword");
          },
        });
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="container mt-4">
        <div className="spare-dvs vh-100 align-items-start">
          <div className="w-100 float-left">
            <div style={{ padding: "8px" }}>
              <img
                src={process.env.PUBLIC_URL + "/images/spare_logo_s.png"}
                alt="Spare Logo"
                className="responsive-image"
                style={{ maxWidth: "30%"}}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                paddingTop: "20px",
                height: "100vh",
              }}
            >
            <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            paddingTop: '20px',
                            height: '100vh'
                        }}>
              <p style={{marginBottom: '10px', fontSize: '15px'}}>Please type in your email and hit submit</p>
              <Form onSubmit={this.handleFormSubmit} style={{ width: "300px" }}>
                <Form.Item>
                  {getFieldDecorator("email", {
                    rules: [{ required: true, message: "Please input your email!" }],
                  })(
                    <Input
                      type="email"
                      placeholder="Enter your email"
                      style={{ width: "100%" }}
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" style={{ width: "150px", marginTop: '10px'}}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Form.create()(ForgotPassword);
