import {ACCESS_TOKEN, LOGOUT} from '../constant/index';

export const tokenAction = (token) => dispatch => {
    dispatch({
        type: ACCESS_TOKEN,
        payload: token
    });
    return Promise.resolve();
};

export const logout = (token) => dispatch => {
    dispatch({
        type: LOGOUT,
        payload: token
    });
    return Promise.resolve();
};


