import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withRecoil from '../withRecoil';
import queryString from "query-string";
import api from '../api/api/index';
import { Form, Input, DatePicker, Modal, Select, Spin } from 'antd';
import moment from 'moment';
import { us_state_abbrevs_names, countries_names } from "../locations"
import { accessTokenState, phoneState, emailState, nameState } from '../atoms';

class Setup2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false, // ✅ New loading state for submit button
    };
  }


  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true }); // ✅ Start loading

    this.props.form.validateFields((err, values) => {
      if (!err && values) {
        let body = {
          access_token: this.props.recoilStates.accessTokenState.state,
          name: values.name,
          dob: values.dob,
          city: values.city,
          state: values.state,
          zipcode: values.zipcode,
          country: values.country
        };

        fetch(`${api.extra}/api/update_user_information`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
          },
          body: queryString.stringify(body)
        })
        .then(response => {
          return response.json().then(data => {
            if (!response.ok) {
              throw new Error(data.message || 'Failed to update profile. Please try again.');
            }
            this.props.goToStep(3);
          });
        })
        .catch((error) => {
          Modal.error({
            title: 'Error',
            content: error.message,
          });
        })
        .finally(() => {
          this.setState({ loading: false }); // ✅ Stop loading
        });
      } else {
        this.setState({ loading: false }); // ✅ Stop loading if validation fails
        Modal.error({
          title: 'Error',
          content: "Form is invalid",
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const minDOB = moment().subtract(18, "years").endOf("day");
    return (
      <div>
        <div style={{ position: 'relative', marginBottom: '20px', textAlign: 'center', width: '100%' }}>
          <div className='spare'>
            <h2 style={{ textAlign: "center", margin: "0" }}>Account Setup</h2>
          </div>
        </div>

        {/* Ensure the form and its wrapper take full width */}
        <div style={{ width: '100%', padding: "10px" }}>
          <Form onSubmit={this.handleSubmit} layout="vertical" style={{ width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: "15px" }}>
              <Form.Item label="Name" style={{ marginRight: '5px', flex: '1', width: '100%' }}>
                {getFieldDecorator('name', {
                    initialValue: this.props.recoilStates.nameState.state,                
                })(
                  <Input readOnly/>
                )}
              </Form.Item>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: "15px" }}>
              <Form.Item label="Phone" style={{ marginRight: '5px', flex: '1', width: '100%' }}>
                {getFieldDecorator('phone', {
                  initialValue: this.props.recoilStates.phoneState.state,
                })(
                  <Input readOnly />
                )}
              </Form.Item>

              <Form.Item label="Date of Birth (must be 18 years or older)" style={{ flex: '1', width: '100%' }}>
                {getFieldDecorator('dob', {
                  rules: [{ required: true, message: 'Please input your date of birth!' }],
                })(
                  <DatePicker placeholder="YYYY-MM-DD" picker="date" style={{ width: '100%' }} 
                  disabledDate={(current) => {
                      return current && current.isAfter(minDOB);
                  }}/>
                )}
              </Form.Item>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: "15px" }}>
              <Form.Item label="Email" style={{ marginRight: '5px', flex: '1', width: '100%' }}>
                {getFieldDecorator('email', {
                  initialValue: this.props.recoilStates.emailState.state,
                })(
                  <Input readOnly />
                )}
              </Form.Item>

              <Form.Item label="City" style={{ flex: '1', width: '100%' }}>
                {getFieldDecorator('city', {
                  rules: [{ required: true, message: 'Please input your city.' }],
                })(
                  <Input />
                )}
              </Form.Item>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: "15px" }}>
              <Form.Item label="State" style={{ marginRight: '5px', flex: '1', width: '100%' }}>
                {getFieldDecorator('state', {
                  rules: [{ required: true, message: 'Please select your state.' }],
                })(
                  <Select showSearch>
                    {Object.entries(us_state_abbrevs_names).map(([stateAbbrev, state]) => (
                      <Select.Option key={stateAbbrev} value={stateAbbrev}>{state}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item label="Zip Code" style={{ marginRight: '5px', flex: '1', width: '100%' }}>
                {getFieldDecorator('zipcode', {
                  rules: [
                    { required: true, message: 'Please input your zip code.' },
                    { len: 5, message: 'Zip code must be 5 digits long' }
                  ],
                })(
                  <Input />
                )}
              </Form.Item>

              <Form.Item label="Country" style={{ flex: '1', width: '100%' }}>
                {getFieldDecorator('country', {
                  initialValue: 'United States',
                  rules: [{ required: true, message: 'Please select your country.' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select a country"
                    style={{ width: '100%' }}
                  >
                    {countries_names.map((country, index) => (
                      <Select.Option key={index} value={country}>
                        {country}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </div>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Form.Item>
                <button className="universal-btn" type="submit" disabled={this.state.loading}>
                  Submit
                </button>
              </Form.Item>

              {this.state.loading && (
                <Spin size="small" style={{ marginLeft: "5px", color: "blue" }} />
              )}
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const WrappedSetup2 = Form.create({ name: 'setup2' })(Setup2);

export default withRouter(withRecoil(WrappedSetup2, [accessTokenState, phoneState, nameState, emailState]));