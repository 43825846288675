import { DefaultValue } from 'recoil';

const sessionStorageEffect = (key) => ({ setSelf, onSet }) => {
  const savedValue = sessionStorage.getItem(key);

  if (savedValue !== null && savedValue !== 'undefined') {
    try {
      setSelf(JSON.parse(savedValue));
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(`Error parsing JSON from sessionStorage for key "${key}":`, error);
      }
      setSelf(new DefaultValue());
    }
  } else {
    setSelf(new DefaultValue());
  }

  onSet((newValue) => {
    if (newValue instanceof DefaultValue) {
      sessionStorage.removeItem(key);
    } else {
      try {
        sessionStorage.setItem(key, JSON.stringify(newValue));
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.error(`Error saving to sessionStorage for key "${key}":`, error);
        }
      }
    }
  });
};

export default sessionStorageEffect;
