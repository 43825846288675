import React from "react";
import { withRouter } from "react-router-dom";
import Header from "../components/Header";

class ChatPage extends React.Component {
  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div>
        <Header />
        <div className="chat-page-container">
          
          {/* Wrapper div to align the back button */}
          <div className="back-button-container">
            <button onClick={this.goBack} className="back-button">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 19l-7-7 7-7"
                  stroke="#333"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <line
                  x1="8"
                  y1="12"
                  x2="20"
                  y2="12"
                  stroke="#333"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>

          <iframe
            src="https://tawk.to/chat/5e39c317a89cda5a18841f2d/1i680d228"
            title="SPARE Chat"
            className="chat-iframe"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    );
  }
}

export default withRouter(ChatPage);