import React, { Component } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import Setup1 from './Setup1';
import Setup2 from './Setup2';
import Setup3a from './Setup3a';
import Setup3b from './Setup3b';
import Setup4 from './Setup4';

class SignupFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completedSteps: [],
    };
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handleBackButton);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleBackButton);
  }

  handleBackButton = (e) => {
      const currentStep = parseInt(this.props.location.pathname.split('setup')[1]);

      if (window.confirm("Are you sure you want to go back? You will have to restart the process.")) {
          if (currentStep === 2) {
              this.props.history.push("/signup");
          } else if (currentStep === 3) {
              this.props.history.replace("/signup-flow/setup2");
          } else if (currentStep > 1) {
              this.props.history.push(`/signup-flow/setup${currentStep - 1}`);
          } else {
              this.props.history.push("/signup");
          }
      } else {
          this.props.history.push(this.props.location.pathname);
      }
  };


  goToStep = (step) => {
    const currentStep = parseInt(this.props.location.pathname.split('setup')[1]);

    if (!this.state.completedSteps.includes(currentStep)) {
      this.setState((prevState) => ({
        completedSteps: [...prevState.completedSteps, currentStep],
      }));
    }

    if (step === 3) {
      this.props.history.replace("/signup-flow/setup3a");
    } else {
      this.props.history.push(`/signup-flow/setup${step}`);
    }
};

goBack = () => {
  const { pathname } = this.props.location;
  const currentStep = parseInt(pathname.split('setup')[1]);

  if (currentStep === 2) {
      this.props.history.push("/signup");
  } else if (currentStep === 3) {
      this.props.history.replace("/signup-flow/setup2");
  } else if (currentStep > 1) {
      this.props.history.push(`/signup-flow/setup${currentStep - 1}`);
  }
};

  render() {
    const { completedSteps } = this.state;

    return (
      <div className="signup-container">
        {/* Progress Bar */}
        <div className="progress-bar">
          <div className="stepper">
            {/* Step 1 */}
            <div className="step">
              <div
                className={`circle ${
                  parseInt(this.props.location.pathname.split('setup')[1]) >= 1 ? 'completed' : ''
                } ${this.props.location.pathname.includes('setup1') ? 'active' : ''}`}
              >
                1
              </div>
              <div className="step-info" data-mobile-text="Phone">
                <h4>Phone Number Verification</h4>
                <p>Enter the code sent to your phone.</p>
              </div>
            </div>

            <div className="line"></div>

            {/* Step 2 */}
            <div className="step">
              <div
                className={`circle ${
                  parseInt(this.props.location.pathname.split('setup')[1]) >= 2 ? 'completed' : ''
                } ${this.props.location.pathname.includes('setup2') ? 'active' : ''}`}
              >
                2
              </div>
              <div className="step-info" data-mobile-text="Set-up">
                <h4>Account Setup</h4>
                <p>Provide complete information of yourself.</p>
              </div>
            </div>

            <div className="line"></div>

            {/* Step 3 */}
            <div className="step">
              <div className={`circle ${
                this.props.location.pathname.includes('setup3a') || 
                this.props.location.pathname.includes('setup3b') ? 'active' : ''
              } ${parseInt(this.props.location.pathname.split('setup')[1]) >= 3 ? 'completed' : ''}`}>
                3
              </div>
              <div className="step-info" data-mobile-text="Scan">
                <h4>Scan ID Card</h4>
                <p>Capture front and back of your ID.</p>
              </div>
            </div>

            <div className="line"></div>

            {/* Step 4 */}
            <div className="step">
              <div
                className={`circle ${
                  parseInt(this.props.location.pathname.split('setup')[1]) >= 4 ? 'completed' : ''
                } ${this.props.location.pathname.includes('setup4') ? 'active' : ''}`}
              >
                4
              </div>
              <div className="step-info" data-mobile-text="Face">
                <h4>Face Scan</h4>
                <p>Capture a picture of yourself.</p>
              </div>
            </div>
          </div>
        </div>

        {/* Step Content */}
        <div className="step-content">
          <Switch>
            <Route exact path="/signup-flow/setup1" component={() => <Setup1 goToStep={this.goToStep} goBack={this.goBack} />} />
            <Route exact path="/signup-flow/setup2" component={() => <Setup2 goToStep={() => this.goToStep(3)} goBack={this.goBack} />} />
            <Route exact path="/signup-flow/setup3a" component={() => <Setup3a goToStep={this.goToStep} goBack={this.goBack} />} />
            <Route exact path="/signup-flow/setup3b" component={() => <Setup3b goToStep={this.goToStep} goBack={this.goBack} />} />
            <Route exact path="/signup-flow/setup4" component={() => <Setup4 goToStep={this.goToStep} goBack={this.goBack} />} />
            <Route path="*" component={() => <Setup1 goToStep={this.goToStep} goBack={this.goBack} />} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(SignupFlow);