import React from "react";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Header from "../components/Header";
import { accessTokenState } from '../atoms';
import withRecoil from '../withRecoil';
import api from "../api/api";
import queryString from "query-string";

class PaymentChoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}

    }

    render() {


        return (
            <div>
                <Header/>
            <div className="payment-container d-flex flex-column justify-content-center align-items-center vh-100">
                <div className="text-center" style={{ width: '90%', maxWidth: '600px' }}>
                    <div style={{ position: 'relative', marginBottom: '20px' }}>
                    <button
                        onClick={() => this.props.history.push({ pathname: '/dashboard' })}
                        style={{
                        position: 'absolute',
                        left: '0',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        border: 'none',
                        background: 'none',
                        cursor: 'pointer',
                        }}
                    >
                        <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            d="M15 19l-7-7 7-7"
                            stroke="#333"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <line
                            x1="8"
                            y1="12"
                            x2="20"
                            y2="12"
                            stroke="#333"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        </svg>
                    </button>
                    <h2 style={{ marginBottom: '0', textAlign: 'center' }}>Top-up balance</h2>
                    </div>

                    <div className="d-flex flex-column align-items-center">
                    <Link
                        to="/dashboard/card-details"
                        className="payment-option btn btn-outline-primary mb-3"
                    >
                        Debit Card
                    </Link>
                    {/* <Link
                        to="/dashboard/payment"
                        className="payment-option btn btn-outline-primary mb-3"
                    >
                        Credit Card
                    </Link> */}
                    {/* <Link to="/dashboard/check" className="payment-option btn btn-outline-primary">
                        Check
                    </Link> */}
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
        },
        dispatch,
    );
const mapStateToProps = state => {
    return {
        token: state.token,
        profile: state.profile
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(withRecoil(PaymentChoice, [accessTokenState])));

