import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { tokenAction } from "../redux-stuffs/actions/token_action";
import { message, Modal, Spin } from 'antd';
import api from '../api/api/index';
import withRecoil from '../withRecoil';
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { accessTokenState} from '../atoms';

class Setup4 extends Component {
  constructor(props) {
    super(props);
    this.videoRef = createRef();
    this.canvasRef = createRef();
    this.state = {
      capturedImage: null,
      loading: false,
      cameraPermission: null,
    };
  }

  componentDidMount() {
    this.startCamera();
  }

  // Function to start the camera
  startCamera = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        this.videoRef.current.srcObject = stream;
        this.setState({cameraPermission: 'granted'})
      })
      .catch((err) => {
        console.error("Error accessing the camera: ", err);
        this.setState({cameraPermission: 'denied'})
      });
  }

  stopCamera = () => {
    const video = this.videoRef.current;
    const stream = video.srcObject;
    const tracks = stream ? stream.getTracks() : [];

    tracks.forEach(track => track.stop());
    video.srcObject = null;
  };

  // Function to capture the image from the video feed
  captureImage = () => {

    const canvas = this.canvasRef.current;
    const video = this.videoRef.current;
    const context = canvas.getContext('2d');

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Save the captured image and remove the video feed
    const imageData = canvas.toDataURL('image/png');
    this.setState({ capturedImage: imageData });

    this.stopCamera();
  };

  // Function to retake the photo
  retakePhoto = () => {
    this.setState({ capturedImage: null });
    this.startCamera();
  };

  handleSubmit = async () => {
    const { capturedImage } = this.state;

    if (!capturedImage) {
      message.error("Error saving captured image. Please try again.");
      this.setState({ capturedImage: null });
      this.startCamera();
      return;
    }

    this.setState({ loading: true });

    const blob = this.dataURLToBlob(capturedImage);
    const formData = new FormData();
    formData.append("file", blob, "selfie.png");
    formData.append("access_token", this.props.recoilStates.accessTokenState.state);

    try {
      const uploadResponse = await fetch(`${api.extra}/api/user_file_upload`, {
        method: "POST",
        body: formData,
      });

      if (!uploadResponse.ok) {
        throw new Error("Selfie upload failed");
      }

      const uploadData = await uploadResponse.json();

      let updateBody = {
        access_token: this.props.recoilStates.accessTokenState.state,
        selfie: uploadData.fileUrl
      };

      const updateResponse = await fetch(`${api.extra}/api/update_user_information`,
      {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
          },
          body: queryString.stringify(updateBody)
      });

      const response = await updateResponse.json();
      if(updateResponse.ok){
        message.success("Selfie uploaded successfully");
        this.props.history.push({ pathname: "/verification-pin" });
      }
      else{
        throw new Error(response.message);
      }
    } catch (error) {
      Modal.error({
        title: 'Error',
        content: error.message || "Failed to upload selfie. Please try again.",
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  // Converts Base64 to Blob
  dataURLToBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];

    const buffer = new ArrayBuffer(byteString.length);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < byteString.length; i++) {
      view[i] = byteString.charCodeAt(i);
    }

    return new Blob([buffer], { type: mimeString });
  };

  render() {
    const { cameraPermission, capturedImage, loading} = this.state;
    return (
      <div>
        <div style={{ position: 'relative', marginBottom: '20px', textAlign: 'center', width: '100%' }}>
          <div className='spare'>
            <h2 style={{ textAlign: "center", margin: "0" }}>Face Scan</h2>
            <p>Please center your face in the circle and capture your photo.</p>
          </div>
        </div>

        <div style={{ position: 'relative', width: '100%', textAlign: 'center' }}>
          {!capturedImage ? (
            <><div style={{ position: 'relative', display: 'inline-block' }}>
              <video ref={this.videoRef} autoPlay playsInline className="video-feed" />
              <canvas ref={this.canvasRef} style={{ display: 'none' }}></canvas>

              {/* Overlay if permission is denied */}
              {cameraPermission === 'denied' && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'grey',
                    color: '#fff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    cursor: 'pointer',
                    padding: '10px',
                    borderRadius: '50%',
                  }}
                >
                  Allow Camera Access to Capture
                </div>
              )}
            </div>

              <div>
                <button
                  className="capture-btn"
                  onClick={this.captureImage}
                  disabled={cameraPermission !== 'granted'}
                >
                  Capture
                </button>
              </div>
            </>
          ) : (
            <>
              <img src={capturedImage} alt="Captured" className="captured-image" />
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <button className="capture-btn" style={{ width: "120px" }} onClick={this.retakePhoto}>Retake</button>
                <button className="capture-btn" style={{ width: "120px" }} onClick={this.handleSubmit} disabled={loading}>
                  Submit
                </button>
                {loading && (
                  <Spin size="small" style={{ marginLeft: "5px", color: "blue" }} />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      tokenAction: tokenAction,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    token: state.token,
    profile: state.profile,
  };
};

// Export the connected Setup4 component
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withRecoil(Setup4, [accessTokenState])));