import env, {envType} from "../config/index";
import Implementation from "./implementation";


let api = {};

switch (env) {
    case envType.DEV:
        api.main = 'https://development.my-spare.com';
        api.extra = 'https://extrastaging.gotspare.com';
        break;
    case envType.PROD:
        api.main = 'https://my-spare.com';
        api.extra = 'https://extra.gotspare.com';
        break;
    case envType.LOCAL:
    default:
        api.main = 'http://development.my-spare.local';
        api.extra = 'http://extra.my-spare.local';
        break;
}

export default api;
