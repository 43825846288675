import React from "react";

class AmountAvailable2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="full-desk w-100 vh-100 float-left d-inline-block header-content">
                <div className="container vh-100">
                    <div className="row vh-100">
                        <div className="col-lg-12 vh-100">
                            <div className="spare-dvs vh-100 align-center">
                                <div className="w-100 float-left">
                                    <h1>Amount Available <br/><sup>$</sup> 25</h1>
                                    <div className="lctn-dvs">
                                        {
                                            //<a href="#">Location 1</a>
                                        }
                                        {
                                        //    <a href="#">Location 2</a>
                                        }
                                        {
                                        //    <a href="#">Location 3</a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
    }
}

export default AmountAvailable2;
