import React from "react";
import {bindActionCreators} from "redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Header from "../components/Header";
import {Checkbox, Col, Form, Input, Modal, Row} from "antd";
import moment from 'moment';
import api from "../api/api";
import SignatureCanvas from 'react-signature-canvas';

class Payment extends React.Component {
    constructor(props) {
        super(props);

        this.frontImage = React.createRef();
        this.backImage = React.createRef();
        this.signatureRef = React.createRef();


        this.state = {
            finalDto: {
                name: "John Doe",
                amount: 20,
            },
            frontImage: false,
            backImage: false,
            signatureImage: false,
        }
    }

    componentDidMount() {
        let currentYear = (new Date()).getFullYear();
        let years = [];
        for (let i = currentYear; i < currentYear + 5; i++) {
            years.push(i);
        }

        this.setState({
            expYears: years
        }, () => {
        });
    }

    handleFormSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.signatureImage = this.state.signatureImage
                this.setState({
                    finalDto: values
                }, () => {
                    this.setState({
                        apiLoading: true
                    }, () => {
                        const thisVar = this;
                        const {finalDto} = this.state;

                        const data = new FormData();
                        data.append("name",finalDto.name);
                        data.append("amount",finalDto.amount);
                        data.append("frontImage",this.state.frontImage);
                        data.append("backImage",this.state.backImage);
                        data.append("signatureImage",this.state.signatureImage);
                        data.append("access_token",this.props.token);

                        // for (var [key, value] of data.entries()) {
                        //     console.log(key, value);
                        // }

                        fetch(`${api.main}/check_cashing_upload`, {
                            method: 'POST',
                            body: data
                        })
                            .then(res => {
                                if (res.status !== 104) {
                                    return Modal.error({
                                        title: "Error",
                                        content: res.message,
                                        onOk: () => {
                                            thisVar.setState({
                                                apiLoading: false,
                                                modalVisible: false,
                                            });
                                        }
                                    })
                                } else {
                                    Modal.success({
                                        title: "Success",
                                        content: "Check Uploaded successfully. We'll notify you when it's finished. If you do not receive any update after 72 hours, please contact support.",
                                        onOk: () => {
                                            thisVar.setState({
                                                apiLoading: false,
                                                modalVisible: false,
                                            }, () => {
                                                thisVar.props.history.push({
                                                    pathname: '/dashboard'
                                                })
                                            });
                                        }
                                    })
                                }
                            })
                            .catch(err => {
                                this.setState({
                                    apiLoading: false,
                                });
                            })
                    });
                });
            }
        });
    };

    frontImageChange = e => {
        this.setState({
            frontImage: e.target.files[0]
        })
    };

    backImageChange = e => {
        this.setState({
            backImage: e.target.files[0]
        })
    };

     // added this to use react-signature-canvas instead of pad
     clearSignature = (e) => {
        e.preventDefault();
        const signatureCanvas = this.signatureRef.current;
        if(signatureCanvas) {
            signatureCanvas.clear();
            this.setState({
                signatureImage: false,
            })
        } else {
            console.error('Signature canvas is undefined.');
        }
    };

    handleCaptureSignature = () => {
        const canvas = this.signatureRef.current;
        const imageDataURL = canvas.toDataURL('image/png');
        // Convert the data URL to a File object
        const signatureImage = this.dataURLtoFile(imageDataURL, 'signature.png');
        // Set the File object in state
        this.setState({ signatureImage }, () => {
            // Log the updated state to check if signatureFile is defined
            //console.log('signatureImage after capture:', this.state.signatureImage);
          });
      };

    dataURLtoFile = (dataURL, filename) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
      };

    render() {

        const {getFieldDecorator} = this.props.form;
        const {expYears, finalDto, modalVisible, tacAgreed, apiLoading} = this.state;

        return (
            <div>
            <Header/>
                <div style={{paddingLeft: "50px", paddingRight: "50px"}}>
                    <div className="spare-dvs align-items-start">
                        {/* <div className="w-100 float-left">
                            <div className="row">
                                <div className="col-lg-6 offset-lg-3"> */}
                                    <div className="card-dtls">
                                        <h1>Check Cashing</h1>

                                        <Form onSubmit={this.handleFormSubmit}>

                                        <div className="row">
                                                <Form.Item className="col-12">
                                                    {getFieldDecorator('amount', {
                                                        rules: [{
                                                            required: true,
                                                            message: 'Please input the amount!'
                                                        }],
                                                    })(
                                                        <p>
                                                            <label> Enter Amount </label>
                                                            <input type="text" placeholder="0.00"/>
                                                        </p>
                                                    )}
                                                </Form.Item>
                                            </div>

                                            <div className="row">
                                                <Form.Item className="col-12">
                                                    {getFieldDecorator('name', {
                                                        rules: [{
                                                            required: true,
                                                            message: 'Please input the name on the check!'
                                                        }],
                                                    })(
                                                        <p>
                                                            <label> Name on Check </label>
                                                            <input type="text" placeholder="e.g. Jane Doe"/>
                                                        </p>
                                                    )}
                                                </Form.Item>
                                            </div>



                                            <br />
                                            <br />
                                            <h3 style={{color: "red"}}>*Please sign your check, and write on it "For Mobile Deposit at Spare only"</h3>
                                            <label>Upload Front Image of Your Check</label>
                                            <input type="file" accept="image/*" name="frontImage" id="frontImage" ref={this.frontImage} /*capture*/ onChange={this.frontImageChange} />
                                            <br />

                                            <label>Upload Back Image of Your Check</label>
                                            <input type="file" accept="image/*" name="backImage" id="backImage" ref={this.backImage} /*capture*/  onChange={this.backImageChange}/>
                                            <br />
                                            <br />
                                            <label style={{display: "flex", justifyContent: "left", alignItems: "left"}}>*Sign Your Name</label>
                                            <br />
                                            <br />
                                            <div className="signature-pad">
                                            <SignatureCanvas clearButton="true" ref= {this.signatureRef} onEnd={this.handleCaptureSignature} />
                                            </div>
                                            <div style={{display: "flex", justifyContent: "left", alignItems: "left", paddingTop:"10px"}}>
                                            <button onClick={this.clearSignature}>Clear Signature</button>
                                            </div>
                                            <br></br>
                                            <br />

                                            <span>*By signing and submitting, you agree that the check you are providing adheres to all financial legalities, has not been cashed previously, and is owned by you. Illegally cashing a check will result in a permanent ban from Spare's system. Furthermore, Spare will contact the appropriate authorities and pursue any and all legal actions.</span>

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <input type="submit" name="" value="Submit" style={{marginTop: '20px'}}
                                                            className="fund-bt" />
                                                    <br></br>
                                                </div>
                                            </div>

                                        </Form>
                                    </div>

                                </div>
                            </div>
                         </div>
        )
    }
}

const WrappedPaymentForm = Form.create({name: 'payment_form'})(Payment);

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            tokenAction: tokenAction
        },
        dispatch,
    );
const mapStateToProps = state => {
    return {
        token: state.token,
        profile: state.profile
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(WrappedPaymentForm));
