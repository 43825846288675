import React from 'react';
import { useRecoilState } from 'recoil';
import { nameState, phoneState, amountState } from './/atoms';

const withRecoil = (WrappedComponent, recoilStates) => {
  return (props) => {
    //console.log('recoilStates:', recoilStates);
    if (!Array.isArray(recoilStates)) {
      //console.log('recoilStates should be an array of Recoil atoms/selectors.');
      return null;
    }
    const stateHandlers = recoilStates.reduce((acc, recoilState) => {
      const [state, setState] = useRecoilState(recoilState);
      acc[recoilState.key] = {
        state,
        setState,
      };
      return acc;
    }, {});

    return <WrappedComponent {...props} recoilStates={stateHandlers} />;
  };
};

export default withRecoil;
