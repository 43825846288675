import React from "react";
import { withRouter } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons, FUNDING } from "@paypal/react-paypal-js";
import api from "../api/api";
import Header from "../components/Header";
import withRecoil from "../withRecoil";
import { accessTokenState } from "../atoms";
import { Modal } from 'antd';

class VenmoPayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = { amount: "20.00" };
    }

    handleAmountChange = (event) => {
        //console.log("New amount:", event.target.value);

        this.setState({ amount: event.target.value });
    };

    createOrder = async () => {
        try {
            if (!this.props.recoilStates.accessTokenState.state) {
                throw new Error("Access token is missing");
            }
            const body ={
                amount: this.state.amount || "1.00",
                access_token: this.props.recoilStates.accessTokenState.state
            }
            //console.log(body);

            const response = await fetch(`${api.extra}/api/paypal/create_order`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(body),
            });

            //console.log("Got response status:", response.status);
            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || `HTTP error! Status: ${response.status}`);
            }

            //console.log("Order created:", data);

            if (!data.orderId) {
                throw new Error("Order ID missing from response");
            }
            //console.log("Backend response from /create_order:", data);
            return data.orderId;
        } catch (error) {
            //console.error("Error creating order", error);
            alert("Failed to create order. Please try again.");
            return null;
        }
    };

    // Capture payment after approval
    captureOrder = async (orderId) => {
      try {
          // console.log("Sending to Venmo - Capture Payment Request:", orderId);

          const accessToken = this.props.recoilStates.accessTokenState.state;

          if (!accessToken) {
              throw new Error("Access token is missing");
          }

          const response = await fetch(`${api.extra}/api/paypal/capture_order`, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: JSON.stringify({ orderId: orderId, access_token: accessToken }),
          });

          const data = await response.json();
          if (!response.ok) {
              throw new Error(data.error || `HTTP error! Status: ${response.status}`);
          }
          // console.log("Payment successful:", data);

          if (this.props.onSuccess) this.props.onSuccess(data);

          if (this.props.history) {
              Modal.success({
                  title: "Success",
                  content: "Your transaction has been submitted for processing. Please allow up to 5 minutes for your balance to be updated.",
                  onOk: () => {
                      this.props.history.push({
                          pathname: "/dashboard"
                      })
                  }
              });
          }
      } catch (error) {
          console.error("Error capturing order", error);
          if (this.props.onError) this.props.onError(error);
      }
    };



    render() {
        return (
            <PayPalScriptProvider options={{ "client-id": "sb" }}> {/* Use "sb" for sandbox */}
                <Header/>
                <div style={styles.container}>
                    <h2 style={styles.heading}>Pay with Venmo</h2>
                    <p
                        style={{
                            borderRadius: "50px",
                            color: "gray",
                            padding: "10px",
                            margin: "5px auto 15px",
                            fontWeight: "normal",
                            width: "75%",
                            textAlign: "center",
                        }}
                    >
                        You will be redirected to your Venmo app to complete this transaction.
                    </p>

                    {/* Amount Input Field */}
                    <div style={styles.inputContainer}>
                        <label style={styles.label}>
                            Amount: $
                            <input
                                type="number"
                                value={this.state.amount}
                                onChange={this.handleAmountChange}
                                step="0.01"
                                min="0.01"
                                style={styles.input}
                            />
                        </label>
                    </div>

                    {/* Venmo Button */}
                    <div style={styles.buttonContainer}>
                        <PayPalButtons
                            style={{ layout: "vertical" }}
                            createOrder={() => {
                                //console.log("Creating Order...");
                                return this.createOrder();
                            }}
                            onApprove={(data) => {
                                // console.log("Payment Approved, Order ID:", data.orderID);
                                return this.captureOrder(data.orderID);
                            }}
                            onError={(err) => {
                                //console.error("Payment error:", err);
                                alert("Payment failed. Please try again.");
                            }}
                        />
                    </div>
                </div>
            </PayPalScriptProvider>
        );
    }
}

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        transform: "translateY(-80px)",
    },
    heading: {
        marginBottom: "15px",
        fontSize: "24px",
        fontWeight: "bold",
    },
    inputContainer: {
        marginBottom: "15px",
    },
    label: {
        fontSize: "18px",
    },
    input: {
        marginLeft: "10px",
        padding: "8px",
        fontSize: "16px",
        borderRadius: "5px",
        border: "1px solid #ccc",
        width: "120px",
        textAlign: "center",
    },
    buttonContainer: {
        width: "300px",
    },
};


export default withRecoil(withRouter(VenmoPayment), [accessTokenState]);
