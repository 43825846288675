import React from "react";
import {Link} from "react-router-dom";

class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="container mt-4">
                <div className="spare-dvs vh-100 align-items-start">
                    <div className="w-100 float-left">
                        <h1>The page you are trying to find was not found</h1>
                        <div className="log-form">
                            <Link to="/login" className="log-btn">Go to home</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NotFound;
