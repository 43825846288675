import React from "react";
import {QrReader} from 'react-qr-reader';
import {Icon, Modal, Input, Spin} from "antd";
import {bindActionCreators} from "redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {saveCashRequestDto} from "../redux-stuffs/actions/profile_action";
import {merchantAddressState, merchantNameState, nameState, phoneState, amountState, accessTokenState} from '../atoms';
import withRecoil from "../withRecoil";
import Barcode from "./Barcode";
import api from "../api/api"
import queryString from "query-string";
import Header from "../components/Header";

const TEN_MINUTES = 10 * 60;

function error(title, content) {
    Modal.error({
        title: title,
        content: content,
    });
}

class Pickup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isApiLoading: false,
            // setIntervalFunction: null,
            result: null,
            pin: '',
            isPinModalOpen: false,
            isScanning: false,
            errorMessage: '',
            isMerchantOpen: false,
            isSuccess: false,
            completeReq: false,
            isQROpen: false,
            showText: true,
            showNFC: false,
            isBarcodeOpen: false,
            barcodeData: "",
            createdAt: null,
            activeTab: 'Phone',
        }
    }

    startScan = (e) => {
        e.preventDefault();
    }

    // scans the qr code and updates result to contain data
    handleScan = (data, error) => {
        if (data) {
            this.setState({ result: data.text, isScanning: false });
            //console.log(this.state.result);
            this.openPinModal();
        }
        if (error) {
            console.info(error);
        }
    };

    //cash request id, phone, pin, name, amount, expiration time
    handleBarcodeData = () => {
        const createdAtDate = new Date(this.state.createdAt);
        console.log("Before:", createdAtDate);
        createdAtDate.setSeconds(createdAtDate.getSeconds() + 600);
        const expiration = createdAtDate.toISOString();
        // const barcodeData = {
        //     cash_request_id: this.props.cashReqDto.cash_request_id,
        //     pin: this.props.cashReqDto.pin,
        //     name: this.props.recoilStates.nameState.state,
        //     phone: this.props.recoilStates.phoneState.state,
        //     amount: this.props.recoilStates.amountState.state,
        //     expiration: expiration
        // };

        //phone,pin,request
        const barcodeData = `${this.props.recoilStates.phoneState.state}${this.props.cashReqDto.pin}${this.props.cashReqDto.cash_request_id}`

        this.setState({ barcodeData }, () => {
            //console.log('Barcode Data:', this.state.barcodeData);
        });
    }


    handlePin = () => {
        const { pin, result } = this.state;
        this.setState({errorMessage: ''})

        this.sendPayload(pin, result)
        .then(responseData => {
            //console.log("response data: ", responseData)
            //console.log("response status: ", responseData.status)

            if(responseData.status === 104)
            {
                //console.log("Success!")
                this.setState({
                    isSuccess: true,
                    errorMessage: '',
                    isPinModalOpen: false,
                })
                this.openMerchantSide();
            }else{
                //console.log('Incorrect pin')
                this.setState({errorMessage: 'Incorrect Pin. Please try again', pin: ''})
            }
        })
        .catch(error=>{
            console.error("Error with pin: ", error)
            this.setState({errorMessage: 'Please try again.'})
        })
    }

    openPinModal = () => {
        this.setState({ isPinModalOpen: true }, () => {
            this.startPolling();
        });    }

    openMerchantSide = () => {
        this.setState({isMerchantOpen: true})
    }

    closePinModal = () => {
        this.setState(prevState => ({
            isPinModalOpen: false,
            result: prevState.isMerchantOpen ? null: prevState.result,
            pin: '',
            isScanning: !prevState.isMerchantOpen,
        }))
    }

    closeMerchantSide = () => {
        this.setState({isMerchantOpen: false})
    }

    handlePinChange = (e) => {
        this.setState({pin: e.target.value})
    }

    // sends the qr id, pin and access token to the backend for verification
    sendPayload = (pin, result) => {
        return new Promise((resolve, reject) => {
            const apiUrl = `${api.main}/ccm/verify_request/qr`;
            // parses result of qr scan to find id
            const parsed = JSON.parse(result);
            const qr_uuid = parsed.qr_uuid;

            //console.log("qr_uuid: ", qr_uuid);
            //console.log("pin: ", pin);
            //console.log("access token: ", this.props.token);

            fetch(apiUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                access_token: this.props.token,
                qr_uuid: qr_uuid,
                pin: pin,
              }),
            })
              .then(response => response.json())
              .then(responseData => {
                //console.log("response data: ", responseData);
                resolve(responseData);
              })
              .catch(error => {
                //console.log(error.message);
                reject(error.message);
              });
          });
    }

    // is called repeatedly by polling interval
    // checks for the status of the transaction on the merchant side
    // once completed or canceled ends repeat with complete request
    requestStatus = async() =>{
        const apiUrl = `${api.main}/cash_request_status`;
        const cashRequest = this.props.cashReqDto.cash_request_id;

        fetch(apiUrl, {
            method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                access_token: this.props.token,
                cash_request_id: cashRequest
              }),
        })
        .then(response => response.json())
        .then(responseData => {
            if (!this.state.createdAt) {
                this.setState({ createdAt: responseData.data.created_at });
                console.log(this.state.createdAt)
            }
        if(responseData.status === "403" )
            {
               // console.log('timed out or failed')
                // cancel the api call and return an error to the user
                // close polling interval
                this.callCancelApi()
                clearInterval(this.pollingInterval);
                this.closeMerchantSide()
                Modal.confirm({
                    title: "Failed Withdrawal",
                    content: responseData.message,
                    onOk: this.props.history.push("/dashboard/amount-available")
                })
            }
            else if(responseData.data.status === "Complete"){
                //console.log('complete')
                // complete request
                fetch(`${api.main}/ccm/complete_request`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        access_token: this.props.token,
                        request_id: cashRequest,
                        status: 'Complete'
                    }),
                })
                this.props.history.push({
                    pathname: '/dashboard/complete'
                })
                clearInterval(this.pollingInterval);
                this.closeMerchantSide()
            }
        })
        .catch(error => {
            error("Error", error.message);
        });

    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          this.handlePin();
        }
      };

    renderPinModal = () => {
        const { isPinModalOpen, pin, errorMessage } = this.state;
        return(
        <Modal
            title="Enter Pin"
            visible={isPinModalOpen}
            onOk={this.handlePin}
            onCancel={this.closePinModal}
            closable={false}
            width={window.innerWidth < 576 ? '100%' : 520}
            >
                <div>
                <p>Enter Pin number: </p>
                <Input type="password" value={pin} onChange={this.handlePinChange} onKeyDown={this.handleKeyPress}/>
                <p style={{ color: 'red' }}>{errorMessage}</p>
                </div>
        </Modal>
        )
    }

    renderMerchant = () => {
        const { isMerchantOpen, isSuccess} = this.state;
        return (
            <Modal
                title="Waiting for Merchant"
                visible={isMerchantOpen}
                closable={false}
                width={window.innerWidth < 576 ? '100%' : 520}
                onCancel={this.cancelCashReq}
                footer={[
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }} key="footer">
                        <button key="submit" onClick={this.goBackToPhoneVerification} style={{border: "1px solid gray", borderRadius: "50px", fontSize: "15px", padding: "10px 10px", color: "gray", backgroundColor: "white"}}>
                            Go Back
                        </button>
                        <button key="submit" type="primary" onClick={this.cancelCashReq} style={{border: "1px solid red", borderRadius: "50px", fontSize: "15px", padding: "10px 10px", color: "red", backgroundColor: "white"}}>
                            Cancel Request
                        </button>
                    </div>
                ]}
                >
                    {isSuccess && (
                    <div>
                        <p>Please wait for merchant to finalize the transaction. </p>
                    </div>
                    )}
            </Modal>
        )
    }

    goBackToPhoneVerification = () => {
        this.setState({
            isMerchantOpen: false,
            activeTab: "Phone",
            showText: true,
            isQROpen: false, 
            isBarcodeOpen: false
        });
    };

    cancelCashReq = () => {
        const thisVar = this;
        Modal.confirm({
            title: "Are you sure?",
            content: "Cash Request cannot be recovered once cancelled.",
            onOk: thisVar.callCancelApi
        })
    };

    componentDidMount = async() => {
        await this.requestStatus()
    }

    componentWillUnmount() {
        this.stopPolling();
    }

    startPolling = () => {
        if (!this.pollingInterval) {
            this.pollingInterval = setInterval(this.requestStatus, 3000);
        }
    };
    
    stopPolling = () => {
        if (this.pollingInterval) {
            clearInterval(this.pollingInterval);
            this.pollingInterval = null;
        }
    };

    callCancelApi = () => {
        const {cashReqDto} = this.props;
        this.setState({
            isApiLoading: true
        }, () => {
            let body = {
                cash_request_id: cashReqDto.cash_request_id,
                action: 0,
                access_token: this.props.recoilStates.accessTokenState.state
            };
            fetch(`${api.main}/request_action_by_customer`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: queryString.stringify(body)
                })
                .then(res => {
                    this.setState({
                        isApiLoading: false
                    });
                    this.props.history.push({
                        pathname: '/dashboard/amount-available'
                    })
                })
                .catch(err => {
                    this.setState({
                        isApiLoading: false
                    });
                })
        });

    };

    handleTabClick = (tabName, action) => {
        this.setState({ activeTab: tabName }, () => {
            action();
    
            if (tabName === "Phone" || tabName === "Barcode") {
                this.startPolling();
            } else {
                this.stopPolling();
            }
        });
    }

    handleScanQRCode = () => {
        this.setState({ isScanning: true, isQROpen: true, showText: false, isBarcodeOpen: false, showNFC: false });

        navigator.mediaDevices.getUserMedia({ video: true })
            .then(() => {
                this.setState({ isScanning: true, isQROpen: true });
            })
            .catch((error) => {
                console.error("Camera access denied:", error);
                this.setState({ isScanning: false});
            });
    }

    handleShowText = () => {
        this.setState({isScanning: false})
        this.setState({isQROpen: false})
        this.setState({showText: true})
        this.setState({isBarcodeOpen: false})
        this.setState({showNFC:false})
    }

    handleNFC = () => {
        this.setState({isScanning: false})
        this.setState({isQROpen: false})
        this.setState({showText: false})
        this.setState({isBarcodeOpen: false})
        this.setState({showNFC:true})
    }

    handleBarcode = () => {
        this.handleBarcodeData();
        //console.log(this.props.cashReqDto)

        this.setState({isScanning: false})
        this.setState({isQROpen: false})
        this.setState({showText: false})
        this.setState({isBarcodeOpen: true})
        this.setState({showNFC:false})
    }

    render() {
        const {cashReqDto} = this.props;
        const {isScanning, isApiLoading, isQROpen, showText, barcodeData, isBarcodeOpen, activeTab, showNFC } = this.state;

        return (
        <div>
            <Header/>
            <div className="container mt-4">
                <div className="spare-dvs vh-100 align-items-start">
                    <div className="w-100 float-left">

                        <h2>Your cash pick up</h2>
                        <h4>Go to the merchant and provide verification to pick up cash</h4>
                        <Spin
                            spinning={isApiLoading}
                            indicator={<Icon type="loading" style={{fontSize: 50, color: "#94220d"}}/>}
                        >
                <div className="pickup-container">
                    {/* Left side */}
                        <div className="flex-item">
                            <div className="info-container">
                                <div className="info-header" style={{marginBottom:"0"}}>
                                    <h4 style={{color: '#888'}}>Pick Up Amount</h4>
                                    <h4>${this.props.recoilStates.amountState.state}</h4>
                                </div>
                            </div>

                            <div className="location-info" style={{border: "1px solid #ddd"}}>
                                <div><p>Merchant for cash pick up</p></div>
                                <div><h4 style={{ fontWeight: "bold" }}>{this.props.recoilStates.merchantNameState.state}</h4></div>
                                <div><p>{this.props.recoilStates.merchantAddressState.state}</p></div>
                            </div>
                           
                            <div className="location-info" style={{border: "1px solid #ddd", marginTop: "5px"}}>
                                <div><h4 style={{ fontWeight: "bold" }}>Pin: {cashReqDto.pin}</h4></div>
                            </div>
                        </div>
                    {/* Right side */}
                        <div className="flex-item">
                            <div className="tab-container">
                                <button
                                    onClick={() => this.handleTabClick('Phone', this.handleShowText)}
                                    className={`function-btns ${activeTab === 'Phone' ? 'active' : ''}`}
                                >
                                Phone
                                </button>

                                <button
                                    onClick={() => this.handleTabClick('QR', this.handleScanQRCode)}
                                    className={`function-btns ${activeTab === 'QR' ? 'active' : ''}`}
                                >
                                QR                                
                                </button>

                                {/* <button
                                    onClick={() => this.handleTabClick('Barcode', this.handleBarcode)}
                                    className={`function-btns ${activeTab === 'Barcode' ? 'active' : ''}`}
                                >
                                Barcode
                                </button> */}

                                {/* <button
                                    onClick={() => this.handleTabClick('NFC', this.handleNFC)}
                                    className={`function-btns ${activeTab === 'NFC' ? 'active' : ''}`}
                                >
                                NFC
                                </button> */}
                            </div>
                            {isQROpen && (
                                <div className="content-box">
                                    <div className="QR-btn">
                                        {this.startScan}
                                        {isScanning ? (
                                            <QrReader
                                                onResult={this.handleScan}
                                                showViewFinder={true}
                                                constraints={{ aspectRatio: 1, facingMode: { ideal: "environment" } }}
                                                className="verify-btn"
                                            />
                                        ) : (
                                            <h4 style={{margin:'10px'}}>
                                            Camera not available. Please try another method or enable.
                                            </h4>
                                        )}
                                    </div>
                                    {this.renderPinModal()}
                                    {this.renderMerchant()}
                                </div>
                            )}
                                {isBarcodeOpen && (
                                    <div className="content-box">
                                        <Barcode value={JSON.stringify(barcodeData)}/>
                                    </div>
                                )}
                                {showNFC && (
                                    <div className="content-box">
                                        <h4 style={{margin:'10px'}}>Tap customer card to cashier</h4>
                                    </div>
                                )}
                                {showText ? (
                                <div className="content-box">
                                    <h4 style={{margin:'10px'}}>Provide your phone number to the cashier along with the pin.</h4>
                                </div>
                                ): null}
                            </div>
                        </div>
                        </Spin>
                        <div className="divider"></div>
                        <input className="universal-btn" type="submit" value="Cancel" name="" onClick={this.cancelCashReq}/>
                </div>
            </div>
        </div>
        </div>
        )
    }
}

const mapDispatchToProps = (dispatch) =>

    bindActionCreators(
      {
        tokenAction,
        saveCashRequestDto,
      },
      dispatch
    );

  const mapStateToProps = (state) => {
    return {
      token: state.token,
      profile: state.profile,
      cashReqDto: state.cashReqDto,
    };
  };

  export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(withRecoil(Pickup, [accessTokenState, merchantAddressState, merchantNameState, nameState, phoneState, amountState]))
  );
