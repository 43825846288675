import React from "react";
import {Form, Select, Button, message, Modal} from "antd";
import {bindActionCreators} from "redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import api from "../api/api";
import {defaultFormValues} from "../api/requiredFunctions";
import {saveCashRequestDto} from "../redux-stuffs/actions/profile_action";
import {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import {merchantAddressState, merchantNameState, amountState, accessTokenState} from '../atoms';
import withRecoil from '../withRecoil';
import queryString from "query-string";
import Header from "../components/Header";

var _ = require('lodash');

const MIN_ESCROW_AMOUNT = 5;
const {Option} = Select;


function error(title, content) {
    Modal.error({
        title: title,
        content: content,
    });
}


class AmountAvailable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            businesses: [],
            apiCalled: false,
            value: 1,
            isApiLoading: false,
            address: '',
            suggestions: [],
            zipcode: '',
            isEditing: true,
            locationLoading: false,
            locationFound: false,
            balance: null,
            map: null,
            markers: [],
            selectedLocation: null,
            i: null,
            long: null,
            lat: null,
            requested_amount: null,
            amountError: null,
        }
    }

    closeOverlay = () => this.setState({ selectedLocation: null });
        
    handleAmountChange = (e) => {
        let value = e.target.value;

        if (!/^\d*$/.test(value)) {
            this.setState({
                requested_amount: null,
                amountError: "Only whole numbers are allowed."
            });
            return;
        }
    
        // Remove non-numeric characters
        value = value.replace(/[^\d]/g, '');
    
        let numericValue = parseInt(value, 10);
        const escrow_amount = this.state.escrow_amount || 0;
    
        // Validation logic
        if (escrow_amount < MIN_ESCROW_AMOUNT) {
            this.setState({
                requested_amount: null,
                amountError: `You need at least ${MIN_ESCROW_AMOUNT} in your balance to withdraw cash`
            });
        } 
        else if(!numericValue || numericValue < MIN_ESCROW_AMOUNT || numericValue > escrow_amount){
            this.setState({
                requested_amount: null,
                amountError: `Amount must be a whole number between $${MIN_ESCROW_AMOUNT} and $${escrow_amount}`
            });
        }
        else {
            this.setState({
                requested_amount: numericValue,
                amountError: ""
            });
        }
    };

    componentWillMount(){
        this.hasActiveCashRequest();
        this.updateEscrow();
    }

    /**
     * finds 9 locations near the zipcode given
     */
    fetchExistingLocations = () => {
        try {
            const { businesses } = this.state;
            const { map } = this.state;
            const markers = [];
            businesses.forEach(business => {
                const position = {lat: business.latitude, lng: business.longitude}
                const marker = new window.google.maps.Marker({
                    position: position,
                    map,
                    title: business.business_name,
                });

                marker.addListener('click', () => {
                    this.handleClick(business);
                });

                markers.push(marker);
            });
            this.setState({ markers });
            //console.log("existing locations fetched")
        } catch (error) {
            return error("Error", error.message);
            //console.log(error.message);
        }
    }

    /**
     * clickable directions button leads them to google page w directions
     */
    findDirections = async () => {
        const location = this.state.selectedLocation
        if (location){
            const { address, business_name } = location
            // directions to address from user location
            const dUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`
            // search result of location name in google maps
            //const sUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(business_name)}`;
            window.open(dUrl, '_blank')
            //window.open(sUrl, '_blank')
        }
    }


    handleClick = (location) => {
        this.setState({ selectedLocation: location, isModalOpen: true});
        //console.log("selected location: ", this.state.selectedLocation);
        //console.log("modal: ", this.state.isModalOpen);
    }

    /**
     * handle functionality of selecting a location from the drop down
     * sends it to the google api to retrieve the zipcode if the location is viable
     */
    handleSelect = async(address, placeId) => {
        this.setState({address})
        geocodeByAddress(address)
        .then((results)=> getLatLng(results[0]))
        .then(({lat, lng}) => {
            //console.log('Lat and Long: ', lat, lng)
            this.setState({lat: lat, long: lng})
            const apiKey = "AIzaSyACt6UKlvoZMTyzXRS9Y4Pcun9ku30r8pY";
            const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
            fetch(apiUrl)
            .then((response) => {
                if(!response.ok){
                    throw new Error(`Status: ${response.status}`)
                }
                return response.json();
            })
            .then((data) => {
                const firstRes = data.results[0]
                const zipcode = firstRes?.address_components.find(
                    (component) => component.types.includes("postal_code")
                )?.short_name;
                if(zipcode){
                    this.setState({zipcode: zipcode, isEditing: false})
                    this.props.form.setFieldsValue({
                        zip: zipcode,
                    })
                }
                else{
                    console.error("No valid zipcode")
                }
            })
            .catch((error) => {
                console.error("Error fetching location details: ", error.message);
            });
        })
        .catch((error) => {
            console.error("Error: There are no available merchants in your selected area.");
        });
    }

    /**
     * shows location options when user begins to type in a location
     */
    handleSearch = (value) => {
        const autocompleter = new window.google.maps.places.AutocompleteService();
        autocompleter.getPlacePredictions(
            {
                input: value,
                types: ['geocode'],
            },
            (predictions, status) => {
                if(status === window.google.maps.places.PlacesServiceStatus.OK){
                    const results = predictions.slice(0, 20);

                    this.setState({suggestions: results})
                }
            }
        )
    }

    handleZipcodeChange = (value) => {
        this.setState({ zipcode: value, isEditing: true });
    };

    /**
     * searches for locations near the zipcode with enough money
     * returning a list of maximum 9 locations
     * returns error if amount is invalid
     */
    handleFormSubmit = (e) => {
        e.preventDefault();
        const { requested_amount, escrow_amount } = this.state;
    
        if (!requested_amount || requested_amount < MIN_ESCROW_AMOUNT || requested_amount > escrow_amount) {
            this.setState({
                amountError: `Amount must be a whole number between $${MIN_ESCROW_AMOUNT} and $${escrow_amount}`
            });
            return;
        }
    
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let finalBody = {
                    ...defaultFormValues(),
                    zip: this.state.zipcode,
                    amount: requested_amount, // Only using requested_amount now
                    access_token: this.props.recoilStates.accessTokenState.state,
                };
    
                fetch(`${api.main}/show_agent`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
                    body: queryString.stringify(finalBody),
                })
                .then(response => response.json())
                .then(res => {
                    if (res.status !== 105) {
                        error("Error", res.message);
                    } else {
                        this.setState({ apiCalled: true, businesses: res.data });
                        this.loadGoogleMapsApi();
                    }
                })
                .catch(() => {
                    error("Error", "Internal Server Error. Please try again later");
                });
            }
        });
    };    

    /**
     * keep track of whether the cash request has expired or not
     */
    hasActiveCashRequest(){
        let body = {
            access_token: this.props.token,
            device_type: 5,
            device_token: 1,
            app_version: 1
        }
        fetch(`${api.main}/access_token_login`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: queryString.stringify(body),
          })
          .then(response => {
            if (!response.ok) {
              // Handle HTTP errors
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json(); // Parse the response body to JSON
          })
            .then(res => {
                if (res.status !== 105) {
                    error("Error", res.message);
                } else {

                    let body2 = {
                        access_token: this.props.recoilStates.accessTokenState.state,
                        request_type: 1
                    };

                    fetch(`${api.main}/cash_request_history`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                        },
                        body: queryString.stringify(body2)
                    })
                    .then(response => {
                        if (!response.ok) {
                          // Handle HTTP errors
                          throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json(); // Parse the response body to JSON
                      })
                        .then(res => {
                            //console.log("request response: ", res.status)
                            //console.log("request data: ", res.data)
                            if (res.status === 105 && res.data) {
                                this.props.history.push({
                                    //pathname: '/dashboard/pickup'
                                })
                            }
                        })
                        .catch(err => {
                            this.props.history.push({
                                pathname: 'login'
                            })
                        })


                    const escrow_amount = _.get(res,'data.escrow_amount');
                    this.setState({
                        escrow_amount
                    });
                    this.props.profile.escrow_amount = res.data.escrow_amount;
                    // let defvalue = escrow_amount < 20 ? escrow_amount : 20;
                    // this.setState({
                    //     defvalue,
                    // });
                }
            })
            .catch(err => {
                //error("Error", "Access token login broke");

                this.props.history.push({
                    pathname: 'login'
                })
            })
    }

    /**
     * initialization of the google maps api
     */
    loadGoogleMapsApi = async() => {
        const loadScript = () => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyACt6UKlvoZMTyzXRS9Y4Pcun9ku30r8pY&libraries=places`;
                script.async = true;
                script.defer = true;
                script.onload = resolve;
                script.onerror = reject;
                document.body.appendChild(script);
            })
        }

        try {
            await loadScript();
            //console.log("Google Maps API loaded successfully");
            await this.initMap();
            await this.fetchExistingLocations();
        } catch (error) {
            console.error("Failed to load Google Maps API: ", error.message);
        }
    }

    /**
     * initializes the map as well as set center to users zipcode
     */
    initMap = async () => {
        try {
          const position = { lat: this.state.lat, lng: this.state.long };
      
          let map = new window.google.maps.Map(document.getElementById("map"), {
            center: position,
            zoom: 10,
          });
      
          let circle = new window.google.maps.Circle({
            strokeColor: '#ADD8E6',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#BAC9FF',
            fillOpacity: 0.35,
            map: map,
            center: position,
            radius: 24140.2,
          });
      
          let marker = new window.google.maps.Marker({
            position: position,
            map: map,
            icon: {
              path: window.google.maps.SymbolPath.CIRCLE,
              scale: 6, 
              fillColor: '#5072ef', 
              fillOpacity: 1,
              strokeWeight: 0, 
            },
            title: 'Center of Radius',
          });
      
          map.fitBounds(circle.getBounds());
      
          this.setState({ map });
         // console.log(this.state.map);
        } catch (error) {
          console.error("Error initializing map ", error.message);
        }
      };      

    /**
     * if user clicks "get current location"
     * function finds zipcode of user's lat and long
     */
    locateZip = async() => {
        //console.log("locateZip")
        //console.log(this.props.profile)

        if("geolocation" in navigator){
            // location tracking here
            this.setState({locationLoading: true})
            navigator.geolocation.getCurrentPosition(
                (position)=> {
                    const { latitude, longitude } = position.coords;
                    //console.log("latitude: " + latitude)
                    //console.log("longitude: " + longitude)
                    this.setState({lat: latitude, long: longitude})
                    const apiKey = "AIzaSyACt6UKlvoZMTyzXRS9Y4Pcun9ku30r8pY";
                    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
                    fetch(apiUrl)
                        .then((response) => {
                            if(!response.ok){
                                throw new Error(`Status: ${response.status}`)
                            }
                            return response.json();
                        })
                        .then((data) => {
                            const firstRes = data.results[0]
                            const zipcode = firstRes?.address_components.find(
                                (component) => component.types.includes("postal_code")
                            )?.short_name;
                            if(zipcode){
                                this.setState({zipcode: zipcode, isEditing: false, address: zipcode})
                                //console.log("Zipcode: " + this.state.zipcode)
                                this.props.form.setFieldsValue({
                                    address: zipcode,
                                    zip: zipcode,
                                })
                                this.setState({locationFound: true})
                                this.setState({locationLoading: false})
                                setTimeout(() => {
                                    this.setState({locationFound: false});
                                  }, 3000);
                            }
                            else{
                                console.error("No valid zipcode")
                                this.setState({locationLoading: false})
                            }
                        })
                        .catch((error) => {
                            console.error("Error fetching location details: ", error.message);
                            this.setState({locationLoading: false})
                        });
                },
                (error) => {
                    console.error("Error getting location: " + error.message);
                    this.setState({locationLoading: false})
                }
            );
        }
        else{
            // geolocation not supported
            this.setState({locationLoading: false})
            //console.log("Geolocation is not supported in this browser")
        }
    }

    /**
     * sends the cash request to the business if the amount selected is within limit
     */
    selectAmount = () => {
        const {businesses, amount, selectedLocation} = this.state;
        const escrow_amount = _.get(this.state, 'escrow_amount', null);

        if (this.state.requested_amount > escrow_amount) {
            return;
        }
        this.setState({
            isApiLoading: true
        }, () => {

            let finalBody = {
                ...defaultFormValues(),
                amount: this.state.requested_amount,
                currency: 1,
                agent_registration_id: selectedLocation.registration_id,
                access_token: this.props.recoilStates.accessTokenState.state
            };

            fetch(`${api.main}/send_cash_request`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: queryString.stringify(finalBody)
                })
                .then(response => {
                    if (!response.ok) {
                      // Handle HTTP errors
                      throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json(); // Parse the response body to JSON
                  })
                .then(res => {
                    this.setState({
                        isApiLoading: false
                    }, () => {
                        if (res.status !== 104) {

                            Modal.error({
                                title: "Error",
                                content: res.message
                            })
                        } else {
                            // atom
                            this.props.recoilStates.merchantAddressState.setState(selectedLocation.address)
                            this.props.recoilStates.merchantNameState.setState(selectedLocation.business_name)
                            this.props.recoilStates.amountState.setState(this.state.requested_amount)
                            this.props.saveCashRequestDto(res.data)
                                .then(_ => {
                                    this.props.history.push({
                                        pathname: '/dashboard/pickup'
                                    })
                                })
                        }
                    });

                })
                .catch(err => {
                    message.error("Internal Server Error. Please try again later");
                    this.setState({
                        isApiLoading: false
                    });
                })
        });
    };

    updateEscrow(){

        let body = {
            access_token: this.props.token,
            device_type: 5,
            device_token: 1,
            app_version: 1
        }
        fetch(`${api.main}/access_token_login`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: queryString.stringify(body),
          })
          .then(response => {
            if (!response.ok) {
              // Handle HTTP errors
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json(); // Parse the response body to JSON
          })
            .then(res => {
                if (res.status !== 105) {
                    error("Error", res.message);
                } else {
                    const escrow_amount = _.get(res,'data.escrow_amount');
                    this.setState({
                        escrow_amount
                    });
                    this.props.profile.escrow_amount = res.data.escrow_amount;
                    // let defvalue = escrow_amount < 20 ? escrow_amount : 20;
                    // this.setState({
                    //     defvalue,
                    // });
                }
            })
            .catch(err => {
                //error("Error", "Access token login broke");

                this.props.history.push({
                    pathname: 'login'
                })
            })
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {businesses, apiCalled, isApiLoading, isModalOpen, selectedLocation} = this.state;
        const { address, suggestions, locationLoading, locationFound, requested_amount } = this.state;

        const escrow_amount = _.get(this.state, 'escrow_amount', null);

        let profile = {
            escrow_amount: escrow_amount
        };

        if(!profile) {
            profile = {
                escrow_amount: -1
            }
        }

        let balance = 0;

        if (escrow_amount > 0 ) {
            let number = escrow_amount.toString()
            balance = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }


        return (
            <div>
                <Header/>
                <div className="container mt-4">
                    <div className="spare-dvs">
                        <div className="w-100 float-left">

                            {apiCalled ?
                                <div className="pickup-container">
                                    {/* Left side/ Bottom */}
                                    <div className="flex-item">
                                    <div style={{ position: 'relative', marginBottom: '20px', textAlign: 'center', width: '100%' }}>
                                        <button onClick={() => window.location.reload()} style={{ position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)', border: 'none', background: 'none', cursor: 'pointer' }}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 19l-7-7 7-7" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <line x1="8" y1="12" x2="20" y2="12" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </button>
                                        <h2 style={{ margin: '0' }}>Select Location</h2>
                                    </div>
                                    <h4>Click on the map to select the cashout location</h4>
                                    <p style={{borderRadius:"50px", backgroundColor:"#faebd5", color:"#d8a65f", padding:"5px", margin:"5px 0 5px", fontWeight:"bold"}}>The search shows results within a 15 mile radius only</p>
                                    <div className="info-container">
                                        <div className="info-header">
                                            <h4 style={{color: '#888'}}>Pick Up Amount</h4>
                                            <h4>${requested_amount}</h4>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="info-content">
                                            <div className="merchant-info">
                                            <img src="../images/location.png" alt="" className="location-icon" />
                                            <p>Showing merchants near</p>
                                            </div>
                                            <h4 className="address">{address}</h4>
                                        </div>
                                    </div>
                            
                                    <br />
                                    </div >
                                    {/* Right side/ Top */}
                                    <div className="flex-item map-container">
                                    <div id="map"></div>
                                        {selectedLocation && (
                                        <div style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%',
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',display: 'flex', alignItems: 'center',
                                            justifyContent: 'center',color: '#fff'}} onClick={this.closeOverlay}
                                        >
                                            <div className="location-info" onClick={(e) => e.stopPropagation()}>
                                                <div style={{display: "flex", justifyContent: "right", alignContent: "right", padding:"0"}}>
                                                    <button style={{border: "none", backgroundColor:"white"}} onClick={this.closeOverlay}><p style={{color: "red"}}>X</p></button>
                                                </div>
                                                <div><p style={{color:"#4C6EF8"}}>Selected Merchant</p></div>
                                                <div><h4 style={{ fontWeight: "bold" }}>{this.state.selectedLocation.business_name}</h4></div>
                                                <div><p>{this.state.selectedLocation.address}</p></div>
                                                <div style={{display: "flex", justifyContent: "center", alignContent: "center", flexDirection:"row"}}>
                                                    <button className="directions-select" onClick={this.findDirections}>Directions</button>
                                                    <button className="directions-select" onClick={this.selectAmount}>Select</button>
                                                </div>
                                            </div>
                                         </div>
                                         )}
                                    </div>
                                </div>
                                :
                                <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
                                    <div style={{ position: 'relative', marginBottom: '20px', textAlign: 'center', width: '100%' }}>
                                        <button onClick={() => this.props.history.push({pathname: '/dashboard'})} style={{ position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)', border: 'none', background: 'none', cursor: 'pointer' }}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 19l-7-7 7-7" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <line x1="8" y1="12" x2="20" y2="12" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </button>
                                        <h2 style={{ margin: '0' }}>Pick up cash</h2>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px', width: '100%', padding: '20px', borderRadius: '8px', border: '1px solid #ccc' }}>
                                    <h4 style={{color: '#888', marginRight: '10px' }}>Your balance</h4>
                                        <h4>${balance}</h4>
                                    </div>
                                    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                                        <h4>Enter amount ($)</h4>
                                        <div style={{ marginTop: '10px' }}>
                                            <input 
                                                placeholder="5"
                                                type="number" 
                                                id="amount" 
                                                name="amount"
                                                min="5"
                                                max={this.state.escrow_amount}
                                                style={{ width: '100%', padding: '10px', fontSize: '24px', textAlign: 'center', borderRadius: '8px', border: '1px solid #ccc' }} 
                                                onChange={this.handleAmountChange}
                                            />
                                            {this.state.amountError && (
                                                <p style={{ color: 'red', marginTop: '5px', fontSize: '14px' }}>
                                                    {this.state.amountError}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div><p>Enter location where you'd like to pick up your cash</p></div>
                                    <Form onSubmit={this.handleFormSubmit} style={{ marginTop: '0px'}}>
                                        <Form.Item validateTrigger="onSubmit">
                                            {getFieldDecorator('address', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input an address!',
                                                    },
                                                ],
                                            })(
                                                <Select
                                                    showSearch
                                                    placeholder="Search address, ZIP codes, or place"
                                                    optionFilterProp="children"
                                                    onSelect={this.handleSelect}
                                                    onSearch={this.handleSearch}
                                                    filterOption={false}
                                                    style={{ width: '100%', padding: '10px', fontSize: '16px', borderRadius: '8px', border: '1px solid #ccc' }}
                                                >
                                                    {suggestions.map((suggestion) => (
                                                        <Option key={suggestion.place_id} value={suggestion.description}>
                                                            {suggestion.description}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                        <button type="button" disabled={locationLoading} onClick={this.locateZip} 
                                        style={{marginTop: '10px', width: '100%', display: 'flex !important', alignItems: 'center !important', justifyContent: 'center !important', padding: '10px 0', border: '1px solid #ccc', borderRadius: '50px', backgroundColor: "white", cursor: 'pointer', height: 'auto'}}>
                                            <img src={"../images/location.png"} alt={""} style={{height: '20px', width: '20px'}}/>
                                            <span style={{marginLeft: '5px'}}>{locationLoading ? "Loading current location..." : "Use current location"}</span>
                                        </button>
                                        <Form.Item>
                                            <input className="universal-btn" style={{padding: "5px 20px"}} type="submit" value="Continue" name="" onClick={this.handleFormSubmit}/>
                                        </Form.Item>
                                    </Form>
                                </div>
                            }
                                </div>
                            </div>
                        </div>
                    </div>
        )
    }
}

const WrappedAmountAvailableForm = Form.create({ name: 'amount_available_form' })(AmountAvailable);

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      tokenAction,
      saveCashRequestDto,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    token: state.token,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(withRecoil(WrappedAmountAvailableForm, [accessTokenState, merchantAddressState, merchantNameState, amountState]))
);
