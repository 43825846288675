import React from "react";
import {Link} from "react-router-dom";

class Spare extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="container mt-4">
                <div className="spare-dvs vh-100 align-items-start">
                    <div className="w-100 float-left">
                        <h1 className="spare" style={{marginTop: "50px", color:"#3366ff"}}>Welcome to SPARE! </h1>
                        <h3 className="spare" style={{marginBottom: "20px"}}>Here's how it works:</h3>
                        <h4 className="spare" style={{marginBottom: "20px"}}>
                        <ol style={{listStylePosition: "inside", textAlign: "center"}}>
                            <li>Add cash to your account from credit, debit, or check.
                            </li>
                            <br></br>
                            <li>Browse nearby locations to submit a request for the amount of cash you want to withdraw.
                            </li>
                            <br></br>
                            <li>Show the merchant your one-time code and get your cash.
                            </li>
                        </ol>
                        </h4>
                        <h4 className="spare">And don't forget to bookmark SPARE for easy access to cash whenever, wherever!</h4>
                        <div className="buttons-container">
                            <Link to={"/dashboard"} className="buttons-container btn" style={{color: "white"}}>
                            Get Started!
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Spare;
