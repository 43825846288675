import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import sessionStorageEffect from './sessionStorage';

const { persistAtom } = recoilPersist();

export const merchantAddressState = atom({
  key: 'merchantAddressState',
  default: '',
  effects: [sessionStorageEffect('merchantAddressState'),],
  effects_UNSTABLE: [persistAtom],
});

export const merchantNameState = atom({
  key: 'merchantNameState',
  default: '',
  effects: [sessionStorageEffect('merchantNameState'),],
  effects_UNSTABLE: [persistAtom],
});

export const nameState = atom({
  key: 'nameState',
  default: '',
  effects: [sessionStorageEffect('nameState'),],
  effects_UNSTABLE: [persistAtom],
});

export const phoneState = atom({
  key: 'phoneState',
  default: '',
  effects: [sessionStorageEffect('phoneState'),],
  effects_UNSTABLE: [persistAtom],
});

export const emailState = atom({
  key: 'emailState',
  default: '',
  effects: [sessionStorageEffect('emailState'),],
  effects_UNSTABLE: [persistAtom],
});

export const amountState = atom({
  key: 'amountState',
  default: '',
  effects: [sessionStorageEffect('amountState'),],
  effects_UNSTABLE: [persistAtom],
});

export const accessTokenState = atom({
  key: 'accessTokenState',
  default: '',
  effects: [sessionStorageEffect('accessTokenState'),],
  effects_UNSTABLE: [persistAtom],
});
