import React from 'react';
import {bindActionCreators} from "redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import Header from "../components/Header";
import {accessTokenState} from '../atoms';
import withRecoil from '../withRecoil';

class Profile extends React.Component {

    render() {
        // const {isToggled} = this.state
        return (
            <div>
                <Header/>
                <div className="help-container d-flex flex-column justify-content-center align-items-center vh-100">
                    <div className='spare'><h2>Account Settings</h2></div>
                    
                    <div className="help-button">
                        <button onClick={()=>this.props.history.push("/edit-profile")} className="help-button btn">Profile</button>
                        <button onClick={()=>this.props.history.push("/change-password")} className="help-button btn">Change Password</button>
                        <button onClick={()=>this.props.history.push("/change-pin")} className="help-button btn">Change Verification Pin</button>
                        <button onClick={()=>this.props.history.push("/promo")} className="help-button btn">Enter Promo Code</button>
                    </div>
                </div>
            </div>
        );
    };
    }

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            tokenAction: tokenAction,
        },
        dispatch,
    );

const mapStateToProps = state => {
    return {
        token: state.token,
        profile: state.profile
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(withRecoil(Profile, [accessTokenState])));
