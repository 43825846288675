import React from 'react';
import { bindActionCreators } from 'redux';
import { tokenAction } from '../redux-stuffs/actions/token_action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal } from 'antd';
import Header from '../components/Header';
import api from '../api/api';
import withRecoil from '../withRecoil';
import { accessTokenState } from '../atoms';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      passwordsMatch: true,
    };
  }

  handlePasswordChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, this.checkPasswordsMatch);
  };

  checkPasswordsMatch = () => {
    const { newPassword, confirmPassword } = this.state;
    this.setState({ passwordsMatch: newPassword === confirmPassword });
  };

  savePasswordChange = () => {
    const { oldPassword, newPassword, confirmPassword, passwordsMatch } = this.state;

    if (!oldPassword || !newPassword || !confirmPassword) {
      Modal.error({
        title: 'Error',
        content: 'Please fill in all fields.',
      });
      return;
    }

    if (!passwordsMatch) {
      Modal.error({
        title: 'Error',
        content: 'New password and confirm password do not match.',
      });
      return;
    }

    if (newPassword.length < 8) {
      Modal.error({
        title: 'Error',
        content: 'New password must be at least 8 characters long.',
      });
      return;
    }
    
    const passwordUpdate = `${api.main}/reset_password`;
    fetch(passwordUpdate, {
      method: 'POST',
      body: JSON.stringify({
        access_token: this.props.recoilStates.accessTokenState.state,
        password: newPassword,
        old_password: oldPassword,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === 104) {
          this.showRedirectModal();
        } else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        Modal.error({
          title: 'Error',
          content: error.message,
        });
      });

    this.setState({ oldPassword: '', newPassword: '', confirmPassword: '' });
  };

  showRedirectModal = () => {
    let countdown = 5;
    const modal = Modal.info({
      title: 'Password Updated Successfully',
      content: `You will be redirected to the login page in ${countdown} seconds.`,
      centered: true,
      okButtonProps: { style: { display: 'none' } },
    });

    const countdownInterval = setInterval(() => {
      countdown--;
      modal.update({
        content: `You will be redirected to the login page in ${countdown} seconds.`,
      });
      if (countdown === 0) {
        clearInterval(countdownInterval);
        modal.destroy();
        this.props.history.push({ pathname: '/login' });
      }
    }, 1000);
  };

  render() {
    const { oldPassword, newPassword, confirmPassword, passwordsMatch } = this.state;

    return (
      <div>
        <Header />
        <div className="container mt-4">
          <div className="profile-container d-flex flex-column align-items-center" style={{ maxWidth: '600px', width: '100%', margin: '0 auto' }}>
            <div style={{ position: 'relative', marginBottom: '20px', textAlign: 'center', width: '100%' }}>
                <button onClick={() => this.props.history.push({pathname: '/profile'})} style={{ position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)', border: 'none', background: 'none', cursor: 'pointer' }}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 19l-7-7 7-7" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <line x1="8" y1="12" x2="20" y2="12" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
                <div className="spare"><h2 style={{textAlign:"center", margin:"0"}}>Change Password</h2></div>
            </div>
            <div className="change-password-form w-100">
              <div className="form-group">
                <div className='spare'><h4>Old Password:</h4></div>
                <input
                  type="password"
                  className="form-control"
                  name="oldPassword"
                  value={oldPassword}
                  onChange={this.handlePasswordChange}
                />
              </div>
              <div className="form-group">
                <div className='spare'><h4>New Password:</h4></div>
                <input
                  type="password"
                  className="form-control"
                  name="newPassword"
                  value={newPassword}
                  onChange={this.handlePasswordChange}
                />
              </div>
              <div className="form-group">
                <div className='spare'><h4>Re-enter New Password:</h4></div>
                <input
                  type="password"
                  className="form-control"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={this.handlePasswordChange}
                />
                {!passwordsMatch && confirmPassword && (
                  <small className="text-danger">Passwords do not match</small>
                )}
                {passwordsMatch && confirmPassword && (
                  <small className="text-success">Passwords match</small>
                )}
              </div>
              <div style={{display: "flex", justifyContent: "center"}}>
              <button
                className="universal-btn"
                onClick={this.savePasswordChange}
                style={{ marginTop: '10px'}}
              >
                Save Changes
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      tokenAction: tokenAction,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    token: state.token,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withRecoil(ChangePassword, [accessTokenState])));
