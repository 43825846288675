import 'antd/dist/antd.css'
import 'react-phone-input-2/lib/style.css';
import "antd/dist/antd.less"; // import official less entry file
import "./custom-antd.less"; // override variables here
import "./override-css.css"; // override variables here
import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import './App.css';
import {hot} from 'react-hot-loader';
import NotFound from "./container/NotFound";
import SignupComponent from "./container/SignupComponent";
import EnterOtp from "./container/EnterOtp";
import SignupVerify from "./container/SignupVerify";
import Spare from "./container/Spare";
import Landing from "./container/Landing";
import Dashboard from "./container/Dashboard";
import LoginComponent from "./container/LoginComponent";
import Profile from "./container/Profile"
import Help from "./container/Help"
import ChatPage from "./container/ChatPage"
import EditProfile from "./container/EditProfile"
import CardDetails from "./container/CardDetails"
import ForgotPassword from './container/ForgotPassword';
import FAQ from "./container/FAQ"
import History from "./container/History"
import ChangePassword from "./container/ChangePassword"
import ChangePin from "./container/ChangePin"
import Promo from "./container/Promo"
import VerificationPin from './container/VerificationPin';
import SignupFlow from "./container/SignupFlow"
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { RecoilRoot } from 'recoil';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import FundAccountPaypal from './container/FundAccountPaypal';


class App extends Component {

    render() {
        return (
            <RecoilRoot>
            <BrowserRouter>
            <div className="App">
                <Switch>
                    <Route exact path='/' component={Landing}/>
                    <Route exact path='/login' component={LoginComponent}/>
                    <Route exact path='/forgotpassword' component={ForgotPassword}/>
                    <Route exact path='/signup' component={SignupComponent}/>
                    <Route path='/signup-flow' component={SignupFlow}/>
                    <Route exact path="/verification-pin" component={VerificationPin}/>
                    <Route path='/enter-otp' component={EnterOtp}/>
                    <Route exact path='/signup-verify' component={SignupVerify}/>
                    <Route exact path='/spare' component={Spare}/>
                    <Route path='/dashboard' component={Dashboard}/>
                    <Route exact path='/profile' component={Profile}/>
                    <Route exact path='/edit-profile' component={EditProfile}/>
                    <Route exact path='/change-password' component={ChangePassword}/>
                    <Route exact path='/change-pin' component={ChangePin}/>
                    <Route exact path='/promo' component={Promo}/>
                    <Route path='/card-details' component={CardDetails}/>
                    <Route exact path='/history' component={History}/>
                    <Route exact path='/help' component={Help}/>
                    <Route exact path='/chat' component={ChatPage}/>
                    <Route exact path='/faq' component={FAQ}/>
                    <Route exact path="/" component={Landing} />
                    <Route exact path="/fund-account-paypal" component={FundAccountPaypal} />
                    <Route exact path='*' component={NotFound}/>
                </Switch>
            </div>
            </BrowserRouter>
            </RecoilRoot>
        );
    }
}

export default hot(module)(App);
