import React from 'react';
import { bindActionCreators } from 'redux';
import { tokenAction } from '../redux-stuffs/actions/token_action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal } from 'antd';
import Header from '../components/Header';
import api from '../api/api';
import withRecoil from '../withRecoil';
import { accessTokenState } from '../atoms';

class ChangePin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPin: '',
      newPin: '',
      confirmPin: '',
      pinsMatch: true,
    };
  }

  handlePinChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, this.checkPinsMatch);
  };

  checkPinsMatch = () => {
    const { newPin, confirmPin } = this.state;
    this.setState({ pinsMatch: newPin === confirmPin });
  };

  savePinChange = () => {
    const { oldPin, newPin, confirmPin, pinsMatch } = this.state;

    if (!oldPin || !newPin || !confirmPin) {
      Modal.error({
        title: 'Error',
        content: 'Please fill in all fields.',
      });
      return;
    }

    if (!pinsMatch) {
      Modal.error({
        title: 'Error',
        content: 'New PIN and confirm PIN do not match.',
      });
      return;
    }

    if (newPin.length < 4) {
      Modal.error({
        title: 'Error',
        content: 'New PIN must be at least 4 characters long.',
      });
      return;
    }

    const pinUpdate = `${api.main}/change_pin`;
    fetch(pinUpdate, {
      method: 'POST',
      body: JSON.stringify({
        access_token: this.props.recoilStates.accessTokenState.state,
        old_pin: oldPin,
        new_pin: newPin,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === 104) {
          this.showSuccessModal();
        } else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        Modal.error({
          title: 'Error',
          content: error.message,
        });
      });

    this.setState({ oldPin: '', newPin: '', confirmPin: '' });
  };

  showSuccessModal = () => {
    Modal.success({
      title: 'PIN Updated Successfully',
      content: 'Your verification PIN has been updated.',
      centered: true,
      onOk: () => this.props.history.push({ pathname: '/profile' }),
    });
  };

  render() {
    const { oldPin, newPin, confirmPin, pinsMatch } = this.state;

    return (
      <div>
        <Header />
        <div className="container mt-4">
          <div className="profile-container d-flex flex-column align-items-center" style={{ maxWidth: '600px', width: '100%', margin: '0 auto' }}>
            <div style={{ position: 'relative', marginBottom: '20px', textAlign: 'center', width: '100%' }}>
                <button onClick={() => this.props.history.push({pathname: '/profile'})} style={{ position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)', border: 'none', background: 'none', cursor: 'pointer' }}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 19l-7-7 7-7" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <line x1="8" y1="12" x2="20" y2="12" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
                <div className="spare"><h2 style={{textAlign:"center", margin:"0"}}>Change Verification Pin</h2></div>
            </div>
            <div className="change-pin-form w-100">
              <div className="form-group">
                <div className='spare'><h4>Old Pin:</h4></div>
                <input
                  type="password"
                  className="form-control"
                  name="oldPin"
                  value={oldPin}
                  onChange={this.handlePinChange}
                />
              </div>
              <div className="form-group">
                <div className='spare'><h4>New Pin:</h4></div>
                <input
                  type="password"
                  className="form-control"
                  name="newPin"
                  value={newPin}
                  onChange={this.handlePinChange}
                />
              </div>
              <div className="form-group">
                <div className='spare'><h4>Re-enter New Pin:</h4></div>
                <input
                  type="password"
                  className="form-control"
                  name="confirmPin"
                  value={confirmPin}
                  onChange={this.handlePinChange}
                />
                {!pinsMatch && confirmPin && (
                  <small className="text-danger">Pins do not match</small>
                )}
                {pinsMatch && confirmPin && (
                  <small className="text-success">Pins match</small>
                )}
              </div>
              <div style={{display: "flex", justifyContent: "center"}}>
              <button
                className="universal-btn"
                onClick={this.savePinChange}
                style={{ marginTop: '10px'}}
              >
                Save Changes
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      tokenAction: tokenAction,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    token: state.token,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withRecoil(ChangePin, [accessTokenState])));