import React from 'react';
import { bindActionCreators } from 'redux';
import { tokenAction } from '../redux-stuffs/actions/token_action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal } from 'antd';
import Header from '../components/Header';
import api from '../api/api';
import withRecoil from '../withRecoil';
import { accessTokenState } from '../atoms';

class Promo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promo: null,
    };
  }

  handlePromo = (e) => {
    this.setState({promo: e.target.value });
  };

  savePromo = () => {
    const promo = this.state.promo;
    
    if (!promo) {
      Modal.error({
        title: 'Error',
        content: 'Please enter a promo code.',
      });
      return;
    }

    if (promo.length > 25) {
      Modal.error({
        title: 'Error',
        content: 'Promo code is max 25 characters long',
      });
      return;
    }
    
    const promo_api = `${api.main}/applyPromo`;
    fetch(promo_api, {
      method: 'POST',
      body: JSON.stringify({
        access_token: this.props.recoilStates.accessTokenState.state,
        promo: promo,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === 104) {
            Modal.success({
                title: 'Success',
                content: "Promo Code successfully applied. Your account should now be credited!",
              });
        } else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        Modal.error({
          title: 'Error',
          content: error.message,
        });
      });

    this.setState({ promo: null});
  };

  render() {
    const { promo } = this.state;

    return (
      <div>
        <Header />
        <div className="container mt-4">
          <div className="profile-container d-flex flex-column align-items-center" style={{ maxWidth: '600px', width: '100%', margin: '0 auto' }}>
            <div style={{ position: 'relative', marginBottom: '20px', textAlign: 'center', width: '100%' }}>
                <button onClick={() => this.props.history.push({pathname: '/profile'})} style={{ position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)', border: 'none', background: 'none', cursor: 'pointer' }}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 19l-7-7 7-7" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <line x1="8" y1="12" x2="20" y2="12" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
                <div className="spare"><h2 style={{textAlign:"center", margin:"0"}}>Enter Promo Code</h2></div>
            </div>
            <div className="change-password-form w-100">
              <div className="form-group">
                <div className='spare'><p>(Maximum 20 characters long)</p></div>
                <input
                  type="text"
                  className="form-control"
                  name="promo"
                  maxLength={25}
                  value={promo}
                  onChange={this.handlePromo}
                />
              </div>
              <div style={{display: "flex", justifyContent: "center"}}>
              <button
                className="universal-btn"
                onClick={this.savePromo}
                style={{ marginTop: '10px'}}
              >
                Submit
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      tokenAction: tokenAction,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    token: state.token,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withRecoil(Promo, [accessTokenState])));