import React from "react";
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
class Landing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0
        };
        this.carouselRef = React.createRef();
    }

    componentDidMount() {
        const carouselElement = this.carouselRef.current;
        const bootstrap = require('bootstrap');

        // Initialize the Bootstrap Carousel
        this.carouselInstance = new bootstrap.Carousel(carouselElement, {
            interval: 5000,
        });

        carouselElement.addEventListener('slid.bs.carousel', (event) => {
            const index = event.to;
            this.setState({ activeIndex: index });
        });
    }

    handlePrev = () => {
        this.carouselInstance.prev();
    };

    handleNext = () => {
        this.carouselInstance.next();
    };

    render() {
        const { activeIndex } = this.state;

        return (
            <div className="container-fluid p-0 min-vh-100">
                <div className="row no-gutters min-vh-100">
                    {/* Logo (40% div) */}
                    <div className="col-12 col-md-5 d-flex flex-column justify-content-center align-items-center text-center order-1 order-md-2 mobile-logo-top">
                        <div className="logo-container logo-lg">
                            <img
                                src={process.env.PUBLIC_URL + "/images/spare_logo_s.png"}
                                alt="Spare Logo"
                                className="responsive-image"
                                style={{ maxWidth: '25%' }}
                            />
                        </div>
                        <div className="container">
                            <div className="spare-dvs d-flex flex-column justify-content-center align-items-center">
                                <div className="w-100 text-center">
                                    <div className="mb-3"></div>
                                    <div>
                                        <span>
                                            <h3 className="d-none d-md-block" style={{ fontWeight: 'bold', fontSize: '20px', paddingBottom: '15px' }}> Welcome to Spare</h3>
                                            <div className="log-form mt-2 d-none d-md-block">
                                                <Link to="/login" className="log-btn" style={{ textDecoration: 'none', padding: '6px', marginTop: '0px' }}>
                                                    Log in
                                                </Link>
                                            </div>
                                            <div className="log-form mt-2 d-none d-md-block">
                                                <Link to="/signup" className="log-btn custom-signup-btn" style={{ textDecoration: 'none', padding: '6px', marginTop: '0px' }}>
                                                    Sign up
                                                </Link>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Carousel (60% div) */}
                    <div className="custom-gradient-div col-12 col-md-7 d-flex flex-column justify-content-center align-items-center order-2 order-md-1 position-relative">
                        <div className="logo-container logo-sm" style={{ position: 'absolute', top: '10px', zIndex: 1000 }}>
                            <img
                                src={process.env.PUBLIC_URL + "/images/spare_logo_s.png"}
                                alt="Spare Logo"
                                className="responsive-image"
                                style={{ maxWidth: '30%' }}
                            />
                        </div>
                        <div className="w-100 text-center" style={{ marginTop: '60px' }}>
                            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" ref={this.carouselRef}>
                                <div className="position-relative" style={{ width: '100%' }}>
                                    {/* Carousel Items */}
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <div className="d-flex flex-column align-items-center">
                                                <img src={process.env.PUBLIC_URL + "/images/onboarding1@4x.png"} className="d-block w-auto" style={{ height: '30vh' }} alt="Onboarding Step 1" />
                                                <br />
                                                <div className="text-center">
                                                    <h5 style={{ color: '#000' }}>
                                                        The most convenient way to access
                                                        <span style={{ display: 'block' }}>real cash</span>
                                                    </h5>
                                                    <span style={{ fontSize: '0.875rem' }}>We're a virtual ATM network, providing fee-free</span>
                                                    <span style={{ fontSize: '0.875rem', display: 'block' }}>access to your cash anytime, anywhere.</span>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="d-flex flex-column align-items-center">
                                                <img src={process.env.PUBLIC_URL + "/images/onboarding2@4x.png"} className="d-block w-auto" style={{ height: '30vh' }} alt="Onboarding Step 2" />
                                                <br />
                                                <div className="text-center">
                                                    <h5 style={{ color: '#000' }}>Keep your balance ready</h5>
                                                    <span style={{ fontSize: '0.875rem' }}>Easily top up your account using credit, debit, or</span>
                                                    <span style={{ fontSize: '0.875rem', display: 'block' }}>check for quick access to cash.</span>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="d-flex flex-column align-items-center">
                                                <img src={process.env.PUBLIC_URL + "/images/onboarding3@4x.png"} className="d-block w-auto" style={{ height: '30vh' }} alt="Onboarding Step 3" />
                                                <br />
                                                <div className="text-center">
                                                    <h5 style={{ color: '#000' }}>Fast withdrawal process</h5>
                                                    <span style={{ fontSize: '0.875rem' }}>Specify the amount you wish to withdraw, choose</span>
                                                    <span style={{ fontSize: '0.875rem', display: 'block' }}>where you'd like to receive your cash, and submit</span>
                                                    <span style={{ fontSize: '0.875rem', display: 'block' }}>your request. It's straightforward.</span>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="d-flex flex-column align-items-center">
                                                <img src={process.env.PUBLIC_URL + "/images/onboarding4@4x.png"} className="d-block w-auto" style={{ height: '30vh' }} alt="Onboarding Step 4" />
                                                <br />
                                                <div className="text-center">
                                                    <h5 style={{ color: '#000' }}>
                                                        Find nearby merchants and get
                                                        <span style={{ display: 'block' }}>your cash</span>
                                                    </h5>
                                                    <span style={{ fontSize: '0.875rem' }}>Find and select nearby locations, show your one-</span>
                                                    <span style={{ fontSize: '0.875rem', display: 'block' }}>time code to the merchant, and receive your cash</span>
                                                    <span style={{ fontSize: '0.875rem', display: 'block' }}>instantly.</span>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Progress Indicator with Previous and Next Buttons */}
                                <div className="d-flex justify-content-center align-items-center mb-3">
                                    <button className="custom-prev-btn" onClick={this.handlePrev}>
                                        <img src={process.env.PUBLIC_URL + "/images/left arrow@1x.png"} alt="Previous" />
                                    </button>

                                    <div className="progress-indicator-container d-flex justify-content-center align-items-center">
                                        {[0, 1, 2, 3].map((index) => (
                                            <span
                                                key={index}
                                                className="progress-indicator"
                                                style={{
                                                    backgroundColor: activeIndex === index ? '#4C6EF8' : 'white',
                                                    width: '25px',
                                                    height: '7px',
                                                    borderRadius: '5px',
                                                    margin: '0 3px'
                                                }}
                                            ></span>
                                        ))}
                                    </div>

                                    <button className="custom-next-btn" onClick={this.handleNext}>
                                        <img src={process.env.PUBLIC_URL + "/images/right arrow@1x.png"} alt="Next" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Buttons for smaller screens */}
                    <div className="col-12 d-md-none" style={{ padding: '20px' }}>
                        <span className="log-form-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                            <span className="log-form mt-2" style={{ width: '80%' }}>
                                <Link to="/login" className="log-btn" style={{ textDecoration: 'none', padding: '6px', marginTop: '0px' }}>
                                    Log in
                                </Link>
                            </span>
                            <span className="log-form mt-2" style={{ width: '80%' }}>
                                <Link to="/signup" className="log-btn custom-signup-btn" style={{ textDecoration: 'none', padding: '6px', marginTop: '15px' }}>
                                    Sign up
                                </Link>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default Landing;