import {ACCESS_TOKEN, LOGOUT} from '../constant';

export const accessTokenReducer = (state = null, action) => {
    switch (action.type) {
        case ACCESS_TOKEN:
            return action.payload;
        case LOGOUT:
            return null;
        default:
            return state;
    }
};
