import React from 'react';
import { bindActionCreators } from "redux";
import { tokenAction } from "../redux-stuffs/actions/token_action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { accessTokenState } from '../atoms';
import withRecoil from '../withRecoil';
import api from '../api/api';
import DOMPurify from "dompurify";
import Header from "../components/Header";

class FAQ extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            faqs: [],
            loading: true,
            error: null
        };
    }

    componentDidMount() {
        // Fetch FAQs from backend API
        fetch(`${api.extra}/api/faq?access_token=${this.props.recoilStates.accessTokenState.state}`)
            .then(response => response.json())
            .then(data => {
                this.setState({ faqs: data.faq, loading: false });
            })
            .catch(error => {
                this.setState({ error: "Failed to load FAQ", loading: false });
            });
    }

    decodeHTML = (html) => {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
      };

    render() {
        const { faqs, loading, error } = this.state;

        return (
            <div>
                <Header />
                <div className="faq-container mt-4">
                    <div style={{ position: 'relative', marginBottom: '20px', textAlign: 'center', width: '100%' }}>
                        <button onClick={() => this.props.history.push({ pathname: '/help' })}
                            style={{
                                position: 'absolute', left: '0', top: '50%',
                                transform: 'translateY(-50%)', border: 'none',
                                background: 'none', cursor: 'pointer'
                            }}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 19l-7-7 7-7" stroke="#333" strokeWidth="2"
                                    strokeLinecap="round" strokeLinejoin="round" />
                                <line x1="8" y1="12" x2="20" y2="12"
                                    stroke="#333" strokeWidth="2"
                                    strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                        <div className='spare'><h2 style={{ textAlign: "center", margin: "0" }}>FAQ</h2></div>
                    </div>

                    {/* ✅ Show loading spinner while fetching */}
                    {loading && <p>Loading FAQs...</p>}

                    {/* ✅ Show error message if API request fails */}
                    {error && <p style={{ color: "red" }}>{error}</p>}

                    {/* ✅ Render FAQ dynamically */}
                    {!loading && !error && faqs.map((faq, index) => (
                        <Accordion key={index} title={faq.question}>
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.decodeHTML(faq.answer)) }} />
                        </Accordion>
                    ))}
                </div>
            </div>
        );
    }
}

class Accordion extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isOpen: false };
        this.toggleAccordion = this.toggleAccordion.bind(this);
    }

    toggleAccordion() {
        this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    }

    render() {
        const { isOpen } = this.state;

        return (
            <div className="accordion-item">
                <div className="accordion-title" onClick={this.toggleAccordion}>
                    {this.props.title}
                    <img src={isOpen ? "./images/drop-up.png" : "./images/drop-down.png"}
                        alt={isOpen ? 'arrow up' : 'arrow down'} />
                </div>
                {isOpen && <div className="accordion-content">{this.props.children}</div>}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators({ tokenAction }, dispatch);

const mapStateToProps = state => ({
    token: state.token,
    profile: state.profile
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withRecoil(FAQ, [accessTokenState])));
