import React, { Component, createRef } from 'react';
import { Form, Input, Modal, message, Spin } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { tokenAction } from "../redux-stuffs/actions/token_action";
import { accessTokenState, phoneState } from "../atoms";
import withRecoil from '../withRecoil';
import queryString from "query-string";
import api from '../api/api/index';
import { withRouter } from 'react-router-dom';

function error(title, content) {
  Modal.error({
      title: title,
      content: content,
  });
}

class Setup1 extends Component {

  constructor(props){
    super(props);
    this.state = {
      otp: new Array(4).fill(""),
      isResendDisabled: false,
      resendTimer: 0,
      loading: false, // ✅ New state for button loading spinner
    };
    this.inputRefs = Array(4).fill().map(() => createRef());
  }

  handleChange = (e, index) => {
    const value = e.target.value;
    if (/^[A-Za-z0-9]{0,1}$/.test(value)) {
      const newOtp = [...this.state.otp];
      newOtp[index] = value;
      this.setState({ otp: newOtp });

      if (value !== "" && index < 3) {
        this.inputRefs[index + 1].current.focus();
      }
    }
  }

  handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && this.state.otp[index] === "") {
      if (index > 0) {
        this.inputRefs[index - 1].current.focus();
      }
    }
  }

  handleSubmit = () => {
    const otpValue = this.state.otp.join("").toLowerCase();
    if (otpValue.length === 4) {
      this.setState({ loading: true }); // ✅ Start loading

      let finalBody = {
        otp: otpValue,
        access_token: this.props.recoilStates.accessTokenState.state
      };

      fetch(`${api.main}/check_otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: queryString.stringify(finalBody)
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(res => {
        if (res.status !== 104) {
          error("Error", res.message);
        } else {
          this.props.tokenAction(this.props.token)
            .then(_ => {
              this.props.goToStep(2);
            });
        }
      })
      .catch(err => {
        error("Error", "Please try again.");
      })
      .finally(() => {
        this.setState({ loading: false }); // ✅ Stop loading
      });
    } else {
      message.error("Please enter the full OTP.");
    }
  }

  handleResend = () => {
    this.setState({ isResendDisabled: true, resendTimer: 30 });

    fetch(`${api.main}/forgot_otp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: queryString.stringify({
        access_token: this.props.recoilStates.accessTokenState.state,
      }),
    })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((errorResult) => {
          throw new Error(errorResult.message || 'An error occurred');
        });
      }
      return response.json();
    })
    .then((res) => {
      if (res.status === 103) {
        message.info('Phone already verified. Moving to the next step.');
        this.props.goToStep(2);
      } else if (res.status === 200) {
        message.success(res.message || 'OTP resent successfully');

        const countdown = setInterval(() => {
          if (this.state.resendTimer === 0) {
            clearInterval(countdown);
            this.setState({ isResendDisabled: false });
          } else {
            this.setState((prevState) => ({
              resendTimer: prevState.resendTimer - 1,
            }));
          }
        }, 1000);
      } else {
        throw new Error(res.message || 'An unexpected error occurred');
      }
    })
    .catch((err) => {
      console.error('Error in handleResend:', err.message);
      message.error(err.message || 'Failed to resend OTP. Please try again.');
      this.setState({ isResendDisabled: false });
    });
  };  
 
  render() {
    return (
      <div>
        <div style={{ position: 'relative', marginBottom: '20px', textAlign: 'center', width: '100%' }}>
          <div className='spare'>
            <h2 style={{ textAlign: "center", margin: "0" }}>Phone Number Verification</h2>
            <p>We sent a verification code to the phone number you signed up with. 
              Please enter the code below.</p>
          </div>
        </div>
        
        {/* Phone Number Verification */}
        <div style={{ position: 'relative', width: '100%', textAlign: 'center' }}>
          <Form className="otp-form">
            <div className="otp-inputs">
              {this.state.otp.map((value, index) => (
                <Form.Item key={index} noStyle>
                  <Input
                    type="text"
                    maxLength="1"
                    value={value}
                    onChange={(e) => this.handleChange(e, index)}
                    onKeyDown={(e) => this.handleKeyDown(e, index)}
                    ref={this.inputRefs[index]}
                    className="otp-input"
                  />
                </Form.Item>
              ))}
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
              <button className="universal-btn" type="link" disabled={this.state.isResendDisabled} onClick={this.handleResend}>
                {this.state.isResendDisabled
                  ? `Resend in ${this.state.resendTimer}s`
                  : 'Resend OTP'}
              </button>

              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <button type="submit" onClick={this.handleSubmit} className="universal-btn" disabled={this.state.loading}>
                  Submit
                </button>

                {this.state.loading && (
                  <Spin size="small" style={{ marginLeft: "10px", color: "blue" }} />
                )}
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      tokenAction: tokenAction,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    token: state.token, 
    profile: state.profile, 
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withRecoil(Setup1, [accessTokenState, phoneState])));