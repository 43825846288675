import React from "react";
import {bindActionCreators} from "redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
//import {withRouter} from "react-router-dom";
import {withRouter} from "react-router";
import {Checkbox, Col, Form, Input, Modal, Row, Button, Select, DatePicker, Card} from "antd";
import moment from 'moment';
import api from "../api/api";
import withRecoil from "../withRecoil";
import { accessTokenState } from "../atoms";
import queryString from "query-string";
import Header from "../components/Header";
import { us_state_abbrevs_names } from "../locations"

const months_arr = {
    1: "January", 2: "February", 3: "March", 4: "April", 5: "May", 6: "June",
    7: "July", 8: "August", 9: "September", 10: "October", 11: "November", 12: "December"
};
class Payment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expYears: [],
            value: 5,
            finalDto: {
                firstname: "John",
                lastname: "Doe",
                amount: 20,
            },
            tacAgreed: false,
            modalVisible: false,
            apiLoading: false,
        }
    }

    handleFormSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {email} = this.props.profile;
                const {total,fee} = this.state;
                values.source_name = "creditcard";
                values.email = email;
                values.total = total;
                values.fee = fee;
                this.setState({
                    finalDto: values
                }, () => {
                    this.handleOk()
                });
            }
        });
    };

    onChange = e => {
        this.setState({
            value: e.target.value,
        });
    };

    handleOk = () => {
        const {finalDto} = this.state;
        finalDto.amount = finalDto.value;

        this.setState({
            apiLoading: true
        }, () => {
            const thisVar = this;
            fetch(`${api.main}/update_user_escrow_amount`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: queryString.stringify({finalDto, access_token: this.props.recoilStates.accessTokenState.state})
                })
                .then(response => {
                    if (!response.ok) {
                      // Handle HTTP errors
                      throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json(); // Parse the response body to JSON
                  })
                .then(res => {
                    if (res.status !== 105) {
                        return Modal.error({
                            title: "Error",
                            content: res.message,
                            onOk: () => {
                                thisVar.setState({
                                    apiLoading: false,
                                    modalVisible: false,
                                });
                            }
                        })
                    } else {
                        Modal.success({
                            title: "Success",
                            content: "Successfully updated escrow amount",
                            onOk: () => {
                                thisVar.setState({
                                    apiLoading: false,
                                    modalVisible: false,
                                }, () => {
                                    thisVar.props.history.push({
                                        pathname: '/dashboard'
                                    })
                                });
                            }
                        })
                    }
                })
                .catch(err => {
                    this.setState({
                        apiLoading: false,
                    });
                })
        });
    };

    amountChange = (e) => {

        // 3% + $0.30

        let amount = e.target.value;

        if(amount < 0){
            amount = Math.abs(amount);
        }

        let fee = (amount * .03) + .3;
        fee = fee.toFixed(2);

        let total = Number(amount) + Number(fee);
        total = total.toFixed(2);

        this.setState({
            amount: amount,
            fee: fee,
            total: total
        });

        //console.log(this.state);
    };

    render() {

        const {getFieldDecorator} = this.props.form;
        const {expYears, finalDto, modalVisible, tacAgreed, apiLoading} = this.state;
        const currentYear = new Date().getFullYear();

        return (
            <div>
                <Header/>
            <div className="outer-container">
                <div className="spare"><h3>Top-up using Credit Card</h3></div>
                <div className="card-container" style={{border:"none"}}>
                    <div className="accpt-dvs">
                        <div className="spare"><p>We Accept</p></div>
                        <img src={require("../assets/images/paymnt.png")}
                                alt="Payment"/>
                    </div>
                    <div>
                        <Form onSubmit={this.handleFormSubmit}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <Form.Item label="Card Holder First Name" style={{ marginRight: '5px', flex: '1' }}>
                                    {getFieldDecorator('firstName', {
                                        rules: [{ required: true, message: 'Please input your first name!' }],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>

                                <Form.Item label="Card Holder Last Name" style={{ flex: '1' }}>
                                    {getFieldDecorator('lastName', {
                                        rules: [{ required: true, message: 'Please input your last name!' }],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Form.Item label="Debit Card Number" style={{ marginRight: '5px', flex: '1' }}>
                                    {getFieldDecorator('cardNumber', {
                                        rules: [
                                            { required: true, message: 'Please input your card number!' },
                                            { len: 16, message: 'Card number must be 16 digits long' }
                                        ],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Form.Item label="Exp. Month" style={{ marginRight: '5px', flex: '1' }}>
                                    {getFieldDecorator('exp_month', {
                                        rules: [{ required: true, message: 'Please select expiration month!' }],
                                    })(
                                        <Select>
                                            {Object.entries(months_arr).map(([value, month]) => (
                                                <Select.Option key={value} value={value}>{month}</Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>

                                <Form.Item label="Exp. Year" style={{ marginRight: '5px', flex: '1' }}>
                                    {getFieldDecorator('exp_year', {
                                        rules: [{ required: true, message: 'Please select expiration year!' }],
                                    })(
                                        <Select>
                                            {Array.from({ length: 51 }, (_, i) => currentYear + i).map(year => (
                                                <Select.Option key={year} value={year}>{year}</Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="CVV" style={{ flex: '1' }}>
                                    {getFieldDecorator('cvv', {
                                        rules: [
                                            { required: true, message: 'Please input your CVV!' },
                                            { len: 3, message: 'CVV must be 3 digits long' }
                                        ],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Form.Item label="Zip Code" style={{ flex: '1' }}>
                                    {getFieldDecorator('zipcode', {
                                        rules: [
                                            { required: true, message: 'Please input your zip code!' },
                                            { len: 5, message: 'Zip code must be 5 digits long' }
                                        ],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </div>

                            <div className="card-container">
                                {/* Deposit Amount */}
                                <Form.Item>
                                    {getFieldDecorator('value', {
                                        rules: [{ required: true, message: 'Please enter an amount.' }],
                                    })(
                                        <div className="deposit-box">
                                            <div className='spare' style={{textAlign: "center", marginTop:"-20px"}}><h4>Enter deposit amount ($)</h4></div>
                                            <Input
                                                type="text"
                                                id="amount"
                                                placeholder="0.00"
                                                style={{ padding: '10px', textAlign: 'center', fontSize: '24px' }}
                                                step="0.01"
                                                onChange={this.amountChange} 
                                                value={this.state.amount} 
                                                min={0}
                                            />
                                        </div>
                                    )}
                                </Form.Item>
                                <div className='divider'></div>
                                {/* Transaction Fee */}
                                <Form.Item>
                                    {getFieldDecorator('fee', {})(
                                        <div className="flex-row">
                                            <label className="flex-label">Transaction Fee</label>
                                            <Input
                                                type="number"
                                                className="readonly-input"
                                                value={this.state.fee} 
                                                style={{ padding: '10px' }}
                                                id="fee"
                                                readOnly
                                            />
                                        </div>
                                    )}
                                </Form.Item>
        
                                {/* Total Amount */}
                                <Form.Item>
                                    {getFieldDecorator('total', {})(
                                        <div className="flex-row">
                                            <label className="flex-label">Total</label>
                                            <Input
                                                type="number"
                                                className="readonly-input"
                                                value={this.state.total} 
                                                style={{ padding: '10px' }}
                                                id="total"
                                                readOnly
                                            />
                                        </div>
                                    )}
                                </Form.Item>
                        </div>
                        <div>
                            <Form.Item>
                                {getFieldDecorator('termsAndConditions', {
                                    rules: [
                                        { required: true, message: ' ' },
                                        {
                                            validator: (rule, value, callback) => {
                                                if (value) {
                                                    callback();
                                                } else {
                                                    callback('Please review the terms and policies.');
                                                }
                                            }
                                        }
                                    ],
                                })(
                                    <div>
                                        <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop:"20px" }}>
                                            <div className="spare" style={{ flex: '1', marginLeft: "10px", marginTop:"5px"}}>
                                                <input type="checkbox" />
                                            </div>
                                            <div className="spare" style={{ flex: '100', marginLeft: "10px", padding:"0px"}}>
                                                <p>
                                                I irrevocably authorize the transaction dated {moment(new Date()).format("MM/DD/YYYY")} to SPARE. I acknowledge having received all the goods/services related to or associated with this transaction, and furthermore agree not to dispute any amount or other aspect of this transaction at any time.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Form.Item> 
                        </div>

                            <Form.Item>
                                <div style={{display: "flex", alignItems: "center", width: "100%", justifyContent: "space-evenly"}}>
                                    <button className="universal-btn" style={{padding: "0px 20px"}} onClick = {() => {this.props.history.push("/dashboard/payment_choice")}}> 
                                        Cancel
                                    </button>
                                    <button type="submit" className="universal-btn" style={{padding: "0px 20px"}}> 
                                        Fund
                                    </button>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

const WrappedPaymentForm = Form.create({name: 'payment_form'})(Payment);

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            tokenAction: tokenAction
        },
        dispatch,
    );
const mapStateToProps = state => {
    return {
        token: state.token,
        profile: state.profile
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(withRecoil(WrappedPaymentForm, [accessTokenState])));
