import React from "react";
import { withRouter } from "react-router-dom";
import Header from "../components/Header";

class Help extends React.Component {

  render() {
    return (
      <div>
        <Header />
        <div className="help-container d-flex flex-column justify-content-center align-items-center vh-100">
          <h2>Help & FAQ</h2>
          <div className="help-button">
            <button onClick={() => this.props.history.push("/chat")} className="help-button btn">
              Open Chat
            </button>
            <a href="tel:+1(424)744-0155" className="help-button btn">
              Call: +1 (424) 744-0155
            </a>
            <button onClick={() => this.props.history.push("/faq")} className="help-button btn">
              FAQ
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Help);