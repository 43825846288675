import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Modal, Spin } from 'antd';
import api from '../api/api';
import withRecoil from '../withRecoil';
import queryString from "query-string";
import { accessTokenState } from '../atoms';

class Setup3b extends Component {
  constructor(props) {
    super(props);
    this.videoRef = createRef();
    this.canvasRef = createRef();
    this.state = {
      capturedImage: null,
      loading: false,
      cameraPermission: null,
    };
  }

  componentDidMount() {
    this.startCamera();
  }

  startCamera = () => {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        if (this.videoRef.current) {
          this.videoRef.current.srcObject = stream;
          this.setState({cameraPermission: 'granted'})
        }
      })
      .catch((err) => {
        console.error("Error accessing the camera: ", err);
        this.setState({cameraPermission: 'denied'})
      });
  };

  stopCamera = () => {
    const video = this.videoRef.current;
    if (video && video.srcObject) {
      video.srcObject.getTracks().forEach(track => track.stop());
      video.srcObject = null;
    }
  };

  captureImage = () => {
    const canvas = this.canvasRef.current;
    const video = this.videoRef.current;

    if (!canvas || !video) {
      console.error("Canvas or video reference is missing.");
      return;
    }

    const context = canvas.getContext('2d');
    if (!context) {
      console.error("Canvas context could not be retrieved.");
      return;
    }

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    this.setState({ capturedImage: canvas.toDataURL('image/png') });
    this.stopCamera();
  };

  retakePhoto = () => {
    this.setState({ capturedImage: null });
    this.startCamera();
  };

  handleSubmit = async () => {
    const { capturedImage } = this.state;
    if (!capturedImage) {
      message.error("Error saving captured image. Please try again.");
      this.startCamera();
      return;
    }

    this.setState({ loading: true }); // ✅ Start loading

    const blob = this.dataURLToBlob(capturedImage);
    const formData = new FormData();
    formData.append("file", blob, "back_id.png");
    formData.append("access_token", this.props.recoilStates.accessTokenState.state);

    try {
      const uploadResponse = await fetch(`${api.extra}/api/user_file_upload`, {
        method: "POST",
        body: formData,
      });

      if (!uploadResponse.ok) throw new Error("Back ID upload failed");

      const uploadData = await uploadResponse.json();
      let updateBody = {
        access_token: this.props.recoilStates.accessTokenState.state,
        photo_id_back: uploadData.fileUrl
      };

      const updateResponse = await fetch(`${api.extra}/api/update_user_information`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        body: queryString.stringify(updateBody)
      });

      if (updateResponse.ok) {
        message.success("Back ID uploaded successfully");
        this.props.history.push("/signup-flow/setup4");
      } else {
        throw new Error("Failed to update user information.");
      }
    } catch (error) {
      Modal.error({ title: 'Error', content: error.message });
    } finally {
      this.setState({ loading: false }); // ✅ Stop loading after request finishes
    }
  };

  handleBack = () => {
    this.props.history.push("/signup-flow/setup3a");
  };

  dataURLToBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const buffer = new ArrayBuffer(byteString.length);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < byteString.length; i++) {
      view[i] = byteString.charCodeAt(i);
    }

    return new Blob([buffer], { type: mimeString });
  };

  render() {
    const {cameraPermission} = this.state;
    return (
      <div>
        <div style={{ textAlign: 'center', width: '100%', marginBottom: '20px' }}>
          <h2>Capture Your ID: Back</h2>
          <p style={{ fontSize: "15px" }}>Please make sure the photo is clear and well-lit.</p>
        </div>

        <div className="camera-container-R">
          {!this.state.capturedImage ? (
            <><div style={{ position: 'relative', display: 'inline-block' }}>
              <video ref={this.videoRef} autoPlay playsInline className="video-feed-R"></video>
              <canvas ref={this.canvasRef} style={{ display: 'none' }}></canvas>

              {/* Overlay if permission is denied */}
              {cameraPermission === 'denied' && (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'grey',
                      color: '#fff',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      cursor: 'pointer',
                      padding: '10px',
                      borderRadius: '10px',
                    }}
                  >
                    Allow Camera Access to Capture
                  </div>
                )}
              </div>
              <div>
                <button className="capture-btn" 
                onClick={this.captureImage}
                disabled={cameraPermission !== 'granted'}
                >Capture
                </button>
              </div>
            </>
          ) : (
            <>
              <div>
                <img src={this.state.capturedImage} alt="Captured ID" className="captured-image-R" />
              </div>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                  <button className="capture-btn" style={{width: "120px"}} onClick={this.retakePhoto}>Retake</button>
                  <button className="capture-btn" style={{ width: "120px" }} onClick={this.handleSubmit} disabled={this.state.loading}>
                    Submit
                  </button>
                  {this.state.loading && (
                    <Spin size="small" style={{ marginLeft: "5px", color: "blue" }} />
                  )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(withRecoil(Setup3b, [accessTokenState]));