import React from 'react';
import { bindActionCreators } from 'redux';
import { tokenAction } from '../redux-stuffs/actions/token_action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, DatePicker, Select, message, Tooltip, Icon, Spin } from 'antd';
import { AiOutlineEdit } from 'react-icons/ai';
import { us_state_abbrevs_names, countries_names } from "../locations"
import moment from 'moment';
import Header from '../components/Header';
import api from '../api/api';
import queryString from 'query-string';
import withRecoil from '../withRecoil';
import { accessTokenState } from '../atoms';

class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profilePic: '',
      newProfilePic: '',
      preview: '',
      name: '',
      city: '',
      state: '',
      zipcode: '',
      country:'',
      phone: '',
      email:'',
      emailVerified: null,
      isUploading: false,
      minDOB: moment().subtract(18, "years"),
    };
  }

  async componentDidMount() {
    await this.fetchProfileDetails();

    const urlParams = new URLSearchParams(window.location.search);
    const emailVerificationToken = urlParams.get("emailVerificationToken");

    if (emailVerificationToken) {
      this.verifyEmailToken(emailVerificationToken);
    }
  }

  verifyEmailToken = async (token) => {
    const { emailVerificationToken } = this.state;
    // Ensure the token from the URL matches
    if ( token !== emailVerificationToken) {
      alert('Error verifying token. Please try again.');
      return;
    }

    //console.log("access token: ", this.props.token);
    try {
      const response = await fetch(`${api.extra}/api/verify_email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        body: queryString.stringify({ access_token: this.props.token}),
      });

      if (response.status === 200) {
        this.setState({ emailVerified: 1 });
        alert('Email verified successfully!');
      } else {
        const errorData = await response.json();
        console.error('Error verifying email:', errorData.message);
        alert('Failed to verify email. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An unexpected error occurred.');
    }
  };

  handleProfilePicChange = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    const maxSize = 2 * 1024 * 1024; // 2MB limit
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

    if (!allowedTypes.includes(file.type)) {
        alert("Invalid file type. Please select a JPG or PNG image.");
        return;
    }

    if (file.size > maxSize) {
        alert("File size exceeds 2MB. Please select a smaller image.");
        return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const size = 300;
            canvas.width = size;
            canvas.height = size;

            const imgRatio = img.width / img.height;
            const canvasRatio = size / size;

            let newWidth, newHeight, xOffset, yOffset;

            if (imgRatio > canvasRatio) {
                newHeight = size;
                newWidth = img.width * (size / img.height);
                xOffset = (size - newWidth) / 2;
                yOffset = 0;
            } else {
                newWidth = size;
                newHeight = img.height * (size / img.width);
                xOffset = 0;
                yOffset = (size - newHeight) / 2;
            }

            ctx.drawImage(img, xOffset, yOffset, newWidth, newHeight);

            canvas.toBlob((blob) => {
                this.setState({ newProfilePic: blob, preview: URL.createObjectURL(blob) }, () => {
                    this.handleProfileUpload();
                });
            }, "image/jpeg", 0.9);
        };
    };

    reader.readAsDataURL(file);
  };


handleProfileUpload = async () => {
    const { newProfilePic } = this.state;

    if (!newProfilePic) {
      message.error("Please select an image.");
      return;
    }

    this.setState({isUploading: true});

    const formData = new FormData();
    formData.append("file", newProfilePic);
    formData.append("access_token", this.props.recoilStates.accessTokenState.state);

    try {
      const response = await fetch(`${api.extra}/api/user_file_upload`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("File upload failed");
      }

      const data = await response.json();
      this.setState({ newProfilePic: data.fileUrl });
      this.setState({ isUploading: false });
      message.success("Success!");
    } catch (error) {
      console.error(error);
      this.setState({ isUploading: false });
      message.error("Error uploading the file. Please try again.");
    }
};

  saveProfileChange = () => {
    const { name, dob, city, state, zipcode, country, newProfilePic } = this.state;
    const { token } = this.props;

    const formData = new FormData();
    formData.append('access_token', token);
    if (name) formData.append('name', name);
    if (dob) formData.append('dob', dob);
    if (city) formData.append('city', city);
    if (state) formData.append('state', state);
    if (zipcode) formData.append('zipcode', zipcode);
    if (country) formData.append('country', country);
    if (newProfilePic) formData.append('profile_pic', newProfilePic);

    fetch(`${api.extra}/api/update_user_information`, {
      method: 'POST',
      body: formData,
    })
    .then((response) => {
      return response.json().then(data => {
        if (!response.ok) {
          throw new Error(data.message || 'Failed to update profile. Please try again.');
        }
        return data;
      });
    })
    .then((data) => {
      Modal.success({
        title: 'Profile Updated',
        content: data.message || 'Your profile has been successfully updated!',
        centered: true,
        onOk: () => {this.fetchProfileDetails()}
      });
    })
    .catch((error) => {
      Modal.error({
        title: 'Error',
        content: error.message,
        onOk: () => {
          this.setState({ newProfilePic: '', preview: '' });
        },
      });
    });
  };

  fetchProfileDetails = async () => {
    try {
      const { token } = this.props;

      if (!token) {
        throw new Error('Access token is missing');
      }

      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      };

      const loginBody = queryString.stringify({
        access_token: token,
        device_type: 3,
        device_token: 1,
        app_version: 1,
      });

      // Fetch profile details
      const loginResponse = await fetch(`${api.main}/access_token_login`, {
        method: 'POST',
        headers,
        body: loginBody,
      });

      if (!loginResponse.ok) {
        throw new Error(`Failed to fetch profile details: ${loginResponse.statusText}`);
      }

      const loginData = await loginResponse.json();

      if (!loginData || !loginData.data) {
        throw new Error('Invalid response structure from /access_token_login');
      }

      const profile = loginData.data;

      // Validate and set the profile picture
      const isValidUrl = (string) => {
        try {
          new URL(string);
          return true;
        } catch {
          return false;
        }
      };
      this.setState({
        profilePic: isValidUrl(profile.profile_pic)
          ? profile.profile_pic
          : 'https://spare-app.s3.us-west-1.amazonaws.com/images/default_profile_pic.png',
        name: profile.name || '',
        phone: profile.phone || '',
        email: profile.email || '',
        emailVerified: profile.email_verified || 0,
        emailVerificationToken: profile.email_verification_token,
      });
      const userInfoBody = queryString.stringify({ access_token: token });

      // Fetch additional user information
      const userInfoResponse = await fetch(`${api.extra}/api/get_user_information`, {
        method: 'POST',
        headers,
        body: userInfoBody,
      });

      if (!userInfoResponse.ok) {
        throw new Error(`Failed to fetch user information: ${userInfoResponse.statusText}`);
      }

      const userInfoData = await userInfoResponse.json();

      // Validate user info response structure
      const user = userInfoData && userInfoData.user ? userInfoData.user : {};
      // Update the state with user info
      this.setState({
        dob: user.dob || '',
        city: user.city || '',
        state: user.state || '',
        zipcode: user.zipcode || '',
        country: user.country || '',
      });
    } catch (error) {
      console.error('Error fetching profile details:', error.message);

      // Display error modal and redirect to login
      Modal.error({
        title: 'Error',
        content: 'Unable to fetch profile details. Please log in again.',
        onOk: () => {
          this.props.history.push('/login');
        },
      });
    }
  };


handleVerifyEmail = async () => {
  const {email, emailVerificationToken} = this.state;
  const token = this.props.recoilStates.accessTokenState.state;


  // Build the verification link
  const isLocalhost = window.location.hostname === "localhost";
  const protocol = window.location.protocol;
  const port = window.location.port;
  const baseUrl = `${protocol}//${window.location.hostname}`;
  const verificationLink = isLocalhost
    ? `${baseUrl}:${port}/edit-profile?emailVerificationToken=${encodeURIComponent(
        emailVerificationToken
      )}`
    : `${baseUrl}/edit-profile?emailVerificationToken=${encodeURIComponent(
        emailVerificationToken
      )}`;
  // Send the verification link to the backend to handle email delivery
  try {
    const response = await fetch(`${api.extra}/api/email_verification_link`, {
      method: "POST",
      headers: { "Content-Type": 'application/x-www-form-urlencoded;charset=UTF-8' },
      body: queryString.stringify({ access_token: token, email: email, verification_link: verificationLink }),
    });

    if (response.status === 200) {
      alert("Verification link sent to your email.");
    }
  } catch (error) {
    alert("Error sending verification link.");
  }
};

handleDOBChange = (date, dateString) => {
  const selectedDate = moment(dateString, "YYYY-MM-DD");
  const minDOB = moment().subtract(18, "years");

  if (selectedDate.isAfter(this.state.minDOB)) {
      alert("You must be at least 18 years old to use this platform.");
      return;
  }
  this.setState({ dob: dateString });
};


  render() {
    const { minDOB, preview, profilePic, name, phone, dob, email, city, state, zipcode, country, emailVerified } = this.state;

    return (
      <div>
        <Header />
        <div className="container mt-4">
          <div
            className="profile-container d-flex flex-column align-items-center"
            style={{ maxWidth: "600px", width: "100%", margin: "0 auto" }}
          >
            <div
              style={{
                position: "relative",
                marginBottom: "20px",
                textAlign: "center",
                width: "100%",
              }}
            >
              <button
                onClick={() =>
                  this.props.history.push({ pathname: "/profile" })
                }
                style={{
                  position: "absolute",
                  left: "0",
                  top: "50%",
                  transform: "translateY(-50%)",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 19l-7-7 7-7"
                    stroke="#333"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <line
                    x1="8"
                    y1="12"
                    x2="20"
                    y2="12"
                    stroke="#333"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <div className="spare">
                <h2 style={{ textAlign: "center", margin: "0" }}>Profile</h2>
              </div>
            </div>
            {/* Profile Picture Section */}
            <div style={{ position: 'relative', width: '100px', height: '100px', marginBottom: '20px' }}>
                {/* Show spinner while uploading */}
                {this.state.isUploading ? (
                    <div style={{
                        width: '100px',
                        height: '100px',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    }}>
                        <Spin size="large" />
                    </div>
                ) : (
                    // Show profile picture when not uploading
                    <img
                        src={this.state.preview || this.state.profilePic}
                        alt="Profile"
                        style={{
                            width: '100px',
                            height: '100px',
                            borderRadius: '50%',
                            objectFit: 'cover',
                            display: 'block',
                        }}
                    />
                )}

                {/* Hidden file input for selecting new profile picture */}
                <input
                    type="file"
                    accept="image/*"
                    onChange={this.handleProfilePicChange}
                    style={{ display: 'none' }}
                    ref={(fileInput) => (this.fileInput = fileInput)}
                />

                {/* Edit icon */}
                <AiOutlineEdit
                    onClick={() => this.fileInput.click()}
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        fontSize: '24px',
                        background: 'white',
                        borderRadius: '50%',
                        padding: '5px',
                        boxShadow: '0 0 5px rgba(0,0,0,0.1)',
                        cursor: 'pointer',
                    }}
                />
            </div>
            {/* Profile Details Form */}
            <div className="profile-details-form w-100">
              <div className="details-row">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control-spare"
                    value={name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </div>
              </div>
              <div className="details-row">
                <div className="form-group">
                  <label>
                    Phone Number
                    <Tooltip title="This is your registered phone number. Contact support if you need to change it.">
                        <Icon type="info-circle" style={{ marginLeft: 5, cursor: 'pointer', color: '#1890ff', position: 'relative', top: '-4px' }} />
                    </Tooltip>
                  </label>
                  <input
                    type="phone"
                    className="form-control-spare"
                    value={phone}
                    style={{background:"#cccf"}}
                    readOnly
                    onChange={(e) => this.setState({ phone: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label>Date of Birth</label>
                  <DatePicker
                    value={this.state.dob ? moment(this.state.dob) : null}
                    onChange={(date, dateString) => this.handleDOBChange(date, dateString)}
                    disabledDate={(current) => {
                        return current && current > minDOB;
                    }}
                  />
                </div>
              </div>
              <div className="details-row">
                <div className="form-group">
                  <label>
                    Email
                    <Tooltip title="This is your registered email. Contact support if you need to change it.">
                        <Icon type="info-circle" style={{ marginLeft: 5, cursor: 'pointer', color: '#1890ff', position: 'relative', top: '-4px' }} />
                    </Tooltip>
                  </label>
                  <div className="email-container">
                    <input
                      type="email"
                      className="form-control-spare"
                      value={email}
                      style={{background:"#cccf"}}
                      readOnly
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                    <button
                      className="verify-button"
                      onClick={this.handleVerifyEmail}
                      disabled={emailVerified === 1}
                    >
                      {emailVerified === 1 ? "Verified ✓" : "Verify"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="details-row">
                <div className="form-group">
                  <label>City</label>
                  <input
                    type="text"
                    className="form-control-spare"
                    value={city}
                    onChange={(e) => this.setState({ city: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label>State</label>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select a state"
                    value={state}
                    onChange={(value) => this.setState({ state: value })}
                  >
                    {Object.entries(us_state_abbrevs_names).map(([stateAbbrev, stateName]) => (
                      <Select.Option key={stateAbbrev} value={stateAbbrev}>
                        {stateName}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="details-row">
                <div className="form-group">
                  <label>Zipcode</label>
                  <input
                    type="text"
                    className="form-control-spare"
                    value={zipcode}
                    onChange={(e) => this.setState({ zipcode: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label>Country</label>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select a country"
                    value={country}
                    onChange={(value) => this.setState({ country: value })}
                  >
                    {countries_names.map((country) => (
                      <Select.Option key={country} value={country}>
                        {country}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
              <button
                className="btn btn-primary"
                onClick={this.saveProfileChange}
                style={{ marginTop: "10px" }}
              >
                Save Changes
              </button>
              <br/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      tokenAction: tokenAction,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    token: state.token,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withRecoil(EditProfile, [accessTokenState])));
