// phone number verification
import React, { Component, createRef } from 'react';
import { Form, Input, Modal, message} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { tokenAction } from "../redux-stuffs/actions/token_action";
import { accessTokenState, phoneState } from "../atoms";
import withRecoil from '../withRecoil';
import queryString from "query-string";
import api from '../api/api/index';
import { withRouter } from 'react-router-dom';

function error(title, content) {
  Modal.error({
      title: title,
      content: content,
  });
}

class VerificationPin extends Component {

  constructor(props){
    super(props);
    this.state = {
      pin: new Array(4).fill(""),
    };
    this.inputRefs = Array(4).fill().map(() => createRef());
  }

  handleChange = (e, index) => {
    const value = e.target.value;

    if (/^\d{0,1}$/.test(value)) {
        const newPin = [...this.state.pin];
        newPin[index] = value;
        this.setState({ pin: newPin });

        // Move to the next input field if a digit is entered
        if (value !== "" && index < this.inputRefs.length - 1) {
            this.inputRefs[index + 1].current.focus();
        }
      }
  };

  handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
        const newPin = [...this.state.pin];

        if (newPin[index] !== "") {
            newPin[index] = "";
            this.setState({ pin: newPin });
            return;
        }

        // Move focus to the previous input if already empty
        if (index > 0) {
            this.inputRefs[index - 1].current.focus();
        }
      }
    };

  handleSubmit = () => {
    const pinValue = this.state.pin.join("");
    if(pinValue.length === 4){
      //console.log("Submitted pin: ", pinValue)
      let finalBody = {
        access_token: this.props.recoilStates.accessTokenState.state,
        pin: pinValue
      };

      fetch(`${api.main}/generate_pin`,{
              method: 'POST',
              headers: {
                  'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
              },
              body: queryString.stringify(finalBody)
          })
          .then(response => {
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              return response.json();
            })
          .then(res => {
              if (res.status !== 104) {
                  error("Error", res.message);
              } else {
                  this.props.tokenAction(this.props.token)
                      .then(_ => {
                        this.props.history.push({pathname: "/spare"})
                      })
                  ;
              }
          })
          .catch(err => {
            error("Error", "Please try again.");
          })
    }
    else{
      message.error("Please enter the full pin.")
    }
  }
 
  render() {
    return (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "100vh"}}>
        <div style={{ position: 'relative', marginBottom: '20px', textAlign: 'center', width: '100%' }}>
          {/* <button onClick={() => this.props.goBack} style={{ position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)', border: 'none', background: 'none', cursor: 'pointer' }}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 19l-7-7 7-7" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <line x1="8" y1="12" x2="20" y2="12" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button> */}
          <div className='spare'>
            <div className="navbar-brand">
                <h2 style={{textDecoration: "none", color: "#4C6EF8", fontWeight: "bold"}}>SPARE</h2>
            </div>
            <h2 style={{ textAlign: "center", margin: "0" }}>Create Verification Pin</h2>
            <p>Please save this pin somewhere safe.</p>
          </div>
        </div>
        {/* Phone Number Verification */}
        <div style={{ position: 'relative', width: '100%', textAlign: 'center' }}>
          <Form className="otp-form">
            <div className="otp-inputs">
              {this.state.pin.map((value, index) => (
                <Form.Item key={index} noStyle>
                  <Input
                    type="text"
                    value={value}
                    onChange={(e) => this.handleChange(e, index)}
                    onKeyDown={(e) => this.handleKeyDown(e, index)}
                    ref={this.inputRefs[index]}
                    className="otp-input"
                    pattern="\d*"
                    inputMode="numeric" 
                    maxLength={1}
                  />
                </Form.Item>
              ))}
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
              <button type="submit" onClick={()=> this.handleSubmit()} className="universal-btn">
                Continue
              </button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      tokenAction: tokenAction,
    },
    dispatch,
  );

const mapStateToProps = (state) => {
  return {
    token: state.token, 
    profile: state.profile, 
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withRecoil(VerificationPin, [accessTokenState, phoneState])));